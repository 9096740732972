import React from 'react';
import { Button } from '../../../shadcn/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../shadcn/components/ui/dialog';
import { useHomePageContext } from '../context/HomepageContext';

type ConfirmExitDialogProps = {
  isDialogOpened: boolean;
  setIsDialogOpened: (open: boolean) => void;
};

const ConfirmExitDialog: React.FC<ConfirmExitDialogProps> = ({
  isDialogOpened,
  setIsDialogOpened,
}) => {
  const context = useHomePageContext();

  return (
    <Dialog open={isDialogOpened} onOpenChange={setIsDialogOpened}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmer la sortie</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Vous avez des données non enregistrées. Voulez-vous vraiment quitter ?
        </DialogDescription>
        <DialogFooter>
          <Button
            variant="destructive"
            onClick={() => {
              context.setIsFormDirty(false);
              setIsDialogOpened(false);
              context.setIsFormOpen(false);
              setTimeout(() => context.setSelectedSale(undefined), 150);
            }}
          >
            Oui, quitter
          </Button>
          <Button
            variant="outline"
            className="hover:bg-success hover:text-white"
            onClick={() => setIsDialogOpened(false)}
          >
            Annuler
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmExitDialog;
