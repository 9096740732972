import { Item } from '../../interfaces/shop';
import { isCollectionImage } from './isCollectionImage';
import { isImage } from './isImage';
import { isStory } from './isStory';

export function isItem(item: any): item is Item {
  if (!item || typeof item !== 'object') {
    return false; // Immediately return false if item is not an object or is null/undefined
  }

  // Check required string properties
  const hasRequiredStrings =
    (typeof item.webpageUrl === 'string' || item.webpageUrl === undefined) &&
    typeof item.dateStart === 'string' &&
    typeof item.dateStop === 'string';

  // Check image object
  const hasValidImage = item.image && isImage(item.image);

  // Check collectionImage object
  const hasValidCollectionImage =
    item.collectionImage && isCollectionImage(item.collectionImage);

  // Check story array
  const hasValidStories =
    Array.isArray(item.story) && item.story.every(isStory);

  return !!(
    hasRequiredStrings &&
    hasValidImage &&
    hasValidStories &&
    hasValidCollectionImage
  );
}
