export type SearchCategoryId =
  | 'Prêt-à-porter'
  | 'Lifestyle'
  | 'Chaussures'
  | 'Accessoires'
  | 'Beauté'
  | 'Enfant'
  | 'Abonnement & Cours'
  | 'Maison'
  | 'Voyage';

export interface SearchCategory {
  id: SearchCategoryId;
  label: string;
  subCategories: SearchSubCategory[];
}

export interface SearchSubCategory {
  id: string;
  label: string;
}

export const SearchCategories: SearchCategory[] = [
  {
    id: 'Prêt-à-porter',
    label: 'Prêt-à-porter',
    subCategories: [
      {
        id: 'Blouses',
        label: 'Blouses',
      },
      {
        id: 'Bodies',
        label: 'Bodies',
      },
      {
        id: 'Costumes',
        label: 'Costumes',
      },
      {
        id: 'Brassières De Sport',
        label: 'Brassières De Sport',
      },
      {
        id: 'Chemises',
        label: 'Chemises',
      },
      {
        id: 'Collants',
        label: 'Collants',
      },
      {
        id: 'Doudounes',
        label: 'Doudounes',
      },
      {
        id: 'Gilets',
        label: 'Gilets',
      },
      {
        id: 'Jeans',
        label: 'Jeans',
      },
      {
        id: 'Joggings',
        label: 'Joggings',
      },
      {
        id: 'Kimono',
        label: 'Kimono',
      },
      {
        id: 'Jupes',
        label: 'Jupes',
      },
      {
        id: 'Leggings',
        label: 'Leggings',
      },
      {
        id: 'Manteaux',
        label: 'Manteaux',
      },
      {
        id: 'Maillots de bain',
        label: 'Maillots de bain',
      },
      {
        id: 'Tops',
        label: 'Tops',
      },
      {
        id: 'Pantalons',
        label: 'Pantalons',
      },
      {
        id: 'Polos',
        label: 'Polos',
      },
      {
        id: 'Ponchos',
        label: 'Ponchos',
      },
      {
        id: 'Pulls',
        label: 'Pulls',
      },
      {
        id: 'Pyjamas',
        label: 'Pyjamas',
      },
      {
        id: 'Robes',
        label: 'Robes',
      },
      {
        id: 'Combinaisons Et Salopettes',
        label: 'Combinaisons Et Salopettes',
      },
      {
        id: 'Shorts',
        label: 'Shorts',
      },
      {
        id: 'Sweatshirts',
        label: 'Sweatshirts',
      },
      {
        id: 'T-Shirts',
        label: 'T-Shirts',
      },
      {
        id: 'Vestes',
        label: 'Vestes',
      },
      {
        id: 'Sous-vêtements',
        label: 'Sous-vêtements',
      },
    ],
  },
  {
    id: 'Lifestyle',
    label: 'Lifestyle',
    subCategories: [
      {
        id: 'Jeux',
        label: 'Jeux',
      },
      {
        id: 'Papeterie',
        label: 'Papeterie',
      },
      {
        id: 'Affiches',
        label: 'Affiches',
      },
      {
        id: 'Photographie',
        label: 'Photographie',
      },
      {
        id: 'Art De La Table',
        label: 'Art De La Table',
      },
      {
        id: 'Valises',
        label: 'Valises',
      },
      {
        id: 'Bougies',
        label: 'Bougies',
      },
      {
        id: 'Cartables',
        label: 'Cartables',
      },
      {
        id: 'Casques moto',
        label: 'Casques moto',
      },
      {
        id: 'Coffrets',
        label: 'Coffrets',
      },
      {
        id: 'Décorations',
        label: 'Décorations',
      },
      {
        id: 'Enceintes & Ecouteurs',
        label: 'Enceintes & Écouteurs',
      },
      {
        id: 'Foutas & Paréos & Serviettes',
        label: 'Foutas & Paréos & Serviettes',
      },
      {
        id: 'Gourdes',
        label: 'Gourdes',
      },
      {
        id: 'Luminaires',
        label: 'Luminaires',
      },
      {
        id: 'Accessoires Téléphones',
        label: 'Accessoires Téléphones',
      },
      {
        id: 'Jouets',
        label: 'Jouets',
      },
      {
        id: 'Literie',
        label: 'Literie',
      },
      {
        id: 'Livres',
        label: 'Livres',
      },
      {
        id: 'Lunettes',
        label: 'Lunettes',
      },
      {
        id: 'Montres',
        label: 'Montres',
      },
      {
        id: 'Bien-être',
        label: 'Bien-être',
      },
      {
        id: 'Maroquinerie',
        label: 'Maroquinerie',
      },
      {
        id: 'Petit électroménager',
        label: 'Petit électroménager',
      },
      {
        id: 'Sacs',
        label: 'Sacs',
      },
      {
        id: 'Sport & Techniques',
        label: 'Sport & Techniques',
      },
      {
        id: 'Parapluies',
        label: 'Parapluies',
      },
      {
        id: 'Plantes',
        label: 'Plantes',
      },
      {
        id: 'Ustensiles De Cuisine',
        label: 'Ustensiles De Cuisine',
      },
    ],
  },
  {
    id: 'Chaussures',
    label: 'Chaussures',
    subCategories: [
      {
        id: 'Ballerines',
        label: 'Ballerines',
      },
      {
        id: 'Baskets',
        label: 'Baskets',
      },
      {
        id: 'Bottes Et Bottines',
        label: 'Bottes Et Bottines',
      },
      {
        id: 'Chaussons',
        label: 'Chaussons',
      },
      {
        id: 'Mocassins',
        label: 'Mocassins',
      },
      {
        id: 'Sandales Et Mules',
        label: 'Sandales Et Mules',
      },
      {
        id: 'Derbies',
        label: 'Derbies',
      },
      {
        id: 'Espadrilles',
        label: 'Espadrilles',
      },
      {
        id: 'Sabots',
        label: 'Sabots',
      },
      {
        id: 'Escarpins',
        label: 'Escarpins',
      },
    ],
  },
  {
    id: 'Accessoires',
    label: 'Accessoires',
    subCategories: [
      {
        id: 'Colliers',
        label: 'Colliers',
      },
      {
        id: 'Bagues',
        label: 'Bagues',
      },
      {
        id: 'Bracelets',
        label: 'Bracelets',
      },
      {
        id: "Boucles d'Oreille",
        label: "Boucles d'Oreille",
      },
      {
        id: 'Piercings',
        label: 'Piercings',
      },
      {
        id: 'Gourdes',
        label: 'Gourdes',
      },
      {
        id: 'Parapluies',
        label: 'Parapluies',
      },
      {
        id: 'Maroquinerie',
        label: 'Maroquinerie',
      },
      {
        id: 'Objets Connectés',
        label: 'Objets Connectés',
      },
      {
        id: 'Téléphonie Et Accessoires',
        label: 'Téléphonie Et Accessoires',
      },
      {
        id: 'Chapeaux, Casquettes Et Bonnets',
        label: 'Chapeaux, Casquettes Et Bonnets',
      },
      {
        id: 'Chaussettes, Collants Et Bas',
        label: 'Chaussettes, Collants Et Bas',
      },
      {
        id: 'Cravates Et Noeuds Papillon',
        label: 'Cravates Et Noeuds Papillon',
      },
      {
        id: 'Echarpes, Foulards Et Gants',
        label: 'Echarpes, Foulards Et Gants',
      },
      {
        id: 'Foutas, Paréos Et Serviettes',
        label: 'Foutas, Paréos Et Serviettes',
      },
      {
        id: 'Casques',
        label: 'Casques',
      },
      {
        id: 'Trousses Et Pochettes',
        label: 'Trousses Et Pochettes',
      },
      {
        id: 'Bagagerie',
        label: 'Bagagerie',
      },
      {
        id: 'Enceintes Et Ecouteurs',
        label: 'Enceintes Et Ecouteurs',
      },
      {
        id: 'Bijoux',
        label: 'Bijoux',
      },
      {
        id: 'Lunettes',
        label: 'Lunettes',
      },
      {
        id: 'Ceintures',
        label: 'Ceintures',
      },
      {
        id: 'Montres',
        label: 'Montres',
      },
      {
        id: 'Petite Maroquinerie',
        label: 'Petite Maroquinerie',
      },
      {
        id: 'Sacs',
        label: 'Sacs',
      },
      {
        id: 'Equipements Sportifs',
        label: 'Equipements Sportifs',
      },
    ],
  },
  {
    id: 'Beauté',
    label: 'Beauté',
    subCategories: [
      {
        id: 'Parfums',
        label: 'Parfums',
      },
      {
        id: 'Cheveux',
        label: 'Cheveux',
      },
      {
        id: 'Corps',
        label: 'Corps',
      },
      {
        id: 'Maquillage',
        label: 'Maquillage',
      },
      {
        id: 'Soins',
        label: 'Soins',
      },
      {
        id: 'Visage',
        label: 'Visage',
      },
    ],
  },
  {
    id: 'Enfant',
    label: 'Enfant',
    subCategories: [
      {
        id: 'Accessoires',
        label: 'Accessoires',
      },
      {
        id: 'Spécial Bébé',
        label: 'Accessoires',
      },
      {
        id: 'Ballerines',
        label: 'Ballerines',
      },
      {
        id: 'Barboteuse',
        label: 'Barboteuse',
      },
      {
        id: 'Collants',
        label: 'Collants',
      },
      {
        id: 'Baskets',
        label: 'Baskets',
      },
      {
        id: 'Bijoux',
        label: 'Bijoux',
      },
      {
        id: 'Bloomers',
        label: 'Bloomers',
      },
      {
        id: 'Bobs',
        label: 'Bobs',
      },
      {
        id: 'Bottes',
        label: 'Bottes',
      },
      {
        id: 'Cartables',
        label: 'Cartables',
      },
      {
        id: 'Casquettes',
        label: 'Casquettes',
      },
      {
        id: 'Ceintures',
        label: 'Ceintures',
      },
      {
        id: 'Chausettes',
        label: 'Chausettes',
      },
      {
        id: 'Chaussons',
        label: 'Chaussons',
      },
      {
        id: 'Chaussures',
        label: 'Chaussures',
      },
      {
        id: 'Coffrets',
        label: 'Coffrets',
      },
      {
        id: 'Combinaisons Et Body',
        label: 'Combinaisons Et Body',
      },
      {
        id: 'Combipilotes',
        label: 'Combipilotes',
      },
      {
        id: 'Echarpes, Foulards Et Gants',
        label: 'Echarpes, Foulards Et Gants',
      },
      {
        id: 'Gigoteuses',
        label: 'Gigoteuses',
      },
      {
        id: 'Jeans',
        label: 'Jeans',
      },
      {
        id: 'Joggings Et Leggings',
        label: 'Joggings Et Leggings',
      },
      {
        id: 'Doudous Et Peluches',
        label: 'Doudous Et Peluches',
      },
      {
        id: 'Jouets',
        label: 'Jouets',
      },
      {
        id: 'Jupes Et Shorts',
        label: 'Jupes Et Shorts',
      },
      {
        id: 'Lunettes',
        label: 'Lunettes',
      },
      {
        id: 'Maillots de bain',
        label: 'Maillots de bain',
      },
      {
        id: 'Pantalons',
        label: 'Pantalons',
      },
      {
        id: 'Petites Décorations',
        label: 'Petites Décorations',
      },
      {
        id: 'Pulls Et Gilets',
        label: 'Pulls Et Gilets',
      },
      {
        id: 'Pyjamas',
        label: 'Pyjamas',
      },
      {
        id: 'Robes',
        label: 'Robes',
      },
      {
        id: 'Sacs',
        label: 'Sacs',
      },
      {
        id: 'Salopettes',
        label: 'Salopettes',
      },
      {
        id: 'Sandales',
        label: 'Sandales',
      },
      {
        id: 'Sweatshirts',
        label: 'Sweatshirts',
      },
      {
        id: 'T-Shirts, Polos et Chemises',
        label: 'T-Shirts, Polos et Chemises',
      },
      {
        id: 'Trousses',
        label: 'Trousses',
      },
      {
        id: 'Vestes, Manteaux et Doudounes',
        label: 'Vestes, Manteaux et Doudounes',
      },
    ],
  },
  {
    id: 'Abonnement & Cours',
    label: 'Abonnement & Cours',
    subCategories: [
      {
        id: 'Expérience',
        label: 'Expérience',
      },
      {
        id: 'Beauté',
        label: 'Beauté',
      },
      {
        id: 'Carte Cadeau',
        label: 'Carte Cadeau',
      },
      {
        id: 'Sport',
        label: 'Sport',
      },
    ],
  },
  {
    id: 'Maison',
    label: 'Maison',
    subCategories: [
      {
        id: 'Jardin Et Bricolage',
        label: 'Jardin Et Bricolage',
      },
      {
        id: 'Décoration',
        label: 'Décoration',
      },
      {
        id: 'Luminaires',
        label: 'Luminaires',
      },
      {
        id: 'Tapis',
        label: 'Tapis',
      },
      {
        id: 'Plantes',
        label: 'Plantes',
      },
      {
        id: 'Bougies',
        label: 'Bougies',
      },
      {
        id: 'Meubles',
        label: 'Meubles',
      },
      {
        id: 'Linge De Bain',
        label: 'Linge De Bain',
      },
      {
        id: 'Linge De Lit',
        label: 'Linge De Lit',
      },
      {
        id: 'Linge De Table',
        label: 'Linge De Table',
      },
      {
        id: 'Art De La Table',
        label: 'Art De La Table',
      },
      {
        id: 'Literie',
        label: 'Literie',
      },
      {
        id: 'Petit Électroménager',
        label: 'Petit Électroménager',
      },
      {
        id: 'Ustensiles De Cuisine',
        label: 'Ustensiles De Cuisine',
      },
    ],
  },
  {
    id: 'Voyage',
    label: 'Voyage',
    subCategories: [
      {
        id: 'Hôtels',
        label: 'Hôtels',
      },
    ],
  },
];
