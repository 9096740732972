import React, { useState } from 'react';
import styled from 'styled-components';
import Loader from '../../../loaders/Loader';
import DownloadBlocIcon from '../../../../assets/icons/download-icon.svg';
import fonts from '../../../../styles/fonts.styles';
import colors from '../../../../styles/colors.styles';
import { uploadFile } from '../../../../services/api/api';
import CloseButton from '../../buttons/CloseButton';
import ImageOrVideo from '../../../miscellaneous/ImageOrVideo';

interface ImageUploaderProps {
  title: string;
  imageFieldName: string;
  imageUrl?: string;
  onSetImage: (path: string) => void;
  onDeleteImage: () => void;
}

const MediaUploader: React.FC<ImageUploaderProps> = ({
  title,
  imageFieldName,
  imageUrl,
  onSetImage,
  onDeleteImage,
}) => {
  const [uploading, setUploading] = useState(false);

  const handleUploadImage = async (event: any) => {
    setUploading(true);

    const file = event.target.files[0];
    if (!file) return;
    const path = await uploadFile(file);

    if (file) {
      onSetImage(path);
    }
    event.target.value = '';
    setUploading(false);
  };

  return (
    <Container>
      <UploadContainer>
        <Title>{title}</Title>
        <ImageContainer>
          {imageUrl && <CloseButtonStyled onClick={() => onDeleteImage()} />}
          <label
            htmlFor={imageFieldName}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <ImageOrVideo
              url={imageUrl || DownloadBlocIcon}
              style={{
                objectFit: 'cover',
                width: imageUrl ? '100%' : '36px',
                height: imageUrl ? '100%' : '36px',
                borderRadius: 6,
              }}
            />
          </label>
          <Input
            id={imageFieldName}
            type="file"
            name={imageFieldName}
            onChange={handleUploadImage}
          />
          <Loader isVisible={uploading} />
        </ImageContainer>
      </UploadContainer>
    </Container>
  );
};

export default MediaUploader;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
`;

const Title = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 12px;
  color: ${colors.blue.primary};
`;

const UploadContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  border: 2px solid ${colors.blue.secondary};
  border-radius: 8px;
  width: 100px;
  height: 93px;
  align-items: center;
  justify-content: center;
  margin-top: 11px;

  &:hover {
    cursor: pointer;
  }

  background-color: ${colors.beige.primary};
`;
