import axios from 'axios';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';

const deleteOngoingSale = async (saleId: number, isLegacySale: boolean) => {
  if (!isLegacySale) {
    await axios.delete(`/v1/ongoing-sales/${saleId}`);
  } else {
    await axios.delete(`modular/ongoingSales/${saleId}`);
  }
};

export const deleteOngoingSaleMutation = (isLegacySale: boolean) => {
  return mutationOptions({
    mutationFn: (saleId) => deleteOngoingSale(saleId, isLegacySale),
    onSuccess: updateCache,
  });
};

const updateCache = (_: void, saleId: number) => {
  queryClient.invalidateQueries({ queryKey: [QueryKeys.ONGOING_SALES] });
};
