import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import styled from 'styled-components';
import { Sheet, SheetContent } from '../../shadcn/components/ui/sheet';
import colors from '../../styles/colors.styles';
import fonts from '../../styles/fonts.styles';
import ConfirmExitDialog from './components/ConfirmExitDialog';
import { SaleForm } from './components/form/SaleForm';
import { HomepagePreview } from './components/HomepagePreview';
import { SaleCreationList } from './components/drag_n_drop/SaleCreationList';
import SaleCreatorBlock from './components/SaleCreatorBlock';
import {
  CATEGORY_UPCOMING_SALLES,
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_SALE_CREATOR_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
} from './constants';
import { useHomePageContext } from './context/HomepageContext';
import { reorderSaleMutation } from './rest/post/postReorderingSale';
import { SaleBlockType } from './types';
import { SortableCategoriesList } from './components/drag_n_drop/SortableCategoriesList';

export const Homepage = () => {
  const context = useHomePageContext();
  const reorderMutation = useMutation(reorderSaleMutation());

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeContainerId = active.data.current?.containerId;

    if (!activeContainerId) {
      return;
    }

    if (activeContainerId === DND_ONGOING_SALE_PREVIEW_ID) {
      const draggedOngoingSale = context
        .getDraggableOngoingSales()
        .find((draggable) => draggable.id === active.id);
      context.setDraggedOngoingSale(draggedOngoingSale);
    } else if (activeContainerId === DND_UPCOMING_SALE_PREVIEW_ID) {
      const draggedUpcomingSale = context
        .getDraggableUpcomingSales()
        .find((draggable) => draggable.id === active.id);
      context.setDraggedUpcomingSale(draggedUpcomingSale);
    } else if (activeContainerId === DND_SALE_CREATOR_ID) {
      const draggableCreationBlock = context.draggableCreationBlocks.find(
        (draggable) => draggable.id === active.id
      );
      context.setDraggedCreationBlock(draggableCreationBlock);
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }

    const activeContainerId = active.data.current?.containerId;
    const overContainerId = over.data.current?.containerId || over.id;

    if (
      !activeContainerId ||
      !overContainerId ||
      activeContainerId === overContainerId
    ) {
      return;
    }

    if (!context.draggedCreationBlock) {
      return;
    }

    const creationBlock = context.draggedCreationBlock;
    if (
      overContainerId === DND_UPCOMING_SALE_PREVIEW_ID ||
      overContainerId === DND_ONGOING_SALE_PREVIEW_ID
    ) {
      context.addToDraggableAllSales(
        overContainerId,
        creationBlock,
        over.data?.current?.sortable.index
      );
      context.generateDraggableCreationBlocks();
    } else if (overContainerId === DND_SALE_CREATOR_ID) {
      context.generateDraggableCreationBlocks();
      context.removeFromDraggables(activeContainerId, creationBlock.id);
      context.setDraggedCreationBlock(undefined);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }

    const activeContainerId = active.data.current?.containerId;
    const overContainerId = over.data.current?.containerId;

    if (
      !activeContainerId ||
      !overContainerId ||
      activeContainerId !== overContainerId
    ) {
      context.setDraggedCreationBlock(undefined);
      context.setDraggedOngoingSale(undefined);
      context.setDraggedUpcomingSale(undefined);
      return;
    }

    if (
      overContainerId === DND_ONGOING_SALE_PREVIEW_ID ||
      overContainerId === DND_UPCOMING_SALE_PREVIEW_ID
    ) {
      const moved = context.rearrangeSalesDraggables(active.id, over.id);
      // Only send the reorder request if the dragged element is a sale from backend
      if (moved && moved.activeSale.id >= 0) {
        reorderMutation.mutate({
          containerId: overContainerId,
          dto: moved.moved,
        });
      }

      if (context.draggedCreationBlock) {
        context.generateDraggableCreationBlocks();
      }
    }

    if (context.draggedCreationBlock) {
      context.setDraggedCreationBlock(undefined);
    }

    if (context.draggedOngoingSale) {
      context.setDraggedOngoingSale(undefined);
    }

    if (context.draggedUpcomingSale) {
      context.setDraggedUpcomingSale(undefined);
    }
  };

  return (
    <Container>
      <ConfirmExitDialog
        isDialogOpened={isDialogOpened}
        setIsDialogOpened={setIsDialogOpened}
      />

      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
      >
        <SaleCreationList
          predicate={(block) =>
            block.creationBlock.blockType === SaleBlockType.NORMAL ||
            context.selectedCategory !== CATEGORY_UPCOMING_SALLES
          }
        />
        <Separator />

        <Sheet
          open={context.isFormOpen}
          // this function is only called when a click outside of the sheet is detected
          onOpenChange={(open) => {
            if (open) {
              return;
            }
            if (context.isFormDirty) {
              setIsDialogOpened(true);
              return;
            }
            // Allows for a smoother transition when closing the sheet
            setTimeout(() => context.setSelectedSale(undefined), 150);
            context.setIsFormOpen(false);
          }}
        >
          <PreviewContainer>
            <Title>APERÇU DE LA PAGE</Title>
            <HomepagePreview />
          </PreviewContainer>

          {context.selectedSale && (
            <SheetContent
              showDefaultClose={false}
              className="flex flex-col gap-y-4 overflow-x-clip overflow-y-scroll rounded-lg border-4 bg-beigePrimary"
              onInteractOutside={(event) => {
                if (context.isFormDirty) {
                  setIsDialogOpened(true);
                  event.preventDefault();
                  return;
                }
                context.setIsFormOpen(false);
                setTimeout(() => context.setSelectedSale(undefined), 150);
              }}
            >
              <SaleForm sale={context.selectedSale} />
            </SheetContent>
          )}
        </Sheet>
        {context.draggedCreationBlock && (
          // Conditionnally rendering DragOverlay isn't recommended but it is the only
          // way to keep the smooth transitions in Sortable lists
          <DragOverlay>
            <SaleCreatorBlock
              isDragging
              title={context.draggedCreationBlock.creationBlock.title}
            />
          </DragOverlay>
        )}
      </DndContext>
      <Separator />
      <div className="flex-1">
        <SortableCategoriesList />
      </div>
    </Container>
  );
};

const Separator = styled.div`
  height: 100vh;
  width: 3px;
  background: ${colors.beige.tertiary};
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  column-gap: 10px;
  overflow: scroll;
  height: 100v;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Title = styled.div`
  font-size: 15px;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.primary};
  padding: 40px;
`;
