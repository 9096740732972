import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Check, CirclePlus } from 'lucide-react';
import { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';
import { Button } from '../../../../shadcn/components/ui/button';
import colors from '../../../../styles/colors.styles';
import fonts from '../../../../styles/fonts.styles';
import { deleteCategoryMutation } from '../../rest/delete/deleteCategory';
import { categoriesQuery } from '../../rest/get/getCategories';
import { postCategoryMutation } from '../../rest/post/postCategory';
import { reorderCategoryMutation } from '../../rest/post/postReorderingCategory';
import { SaleCategory } from '../../types';
import { DraggableCategoryItem } from './DraggableCategoryItem';

export const SortableCategoriesList = () => {
  const { data: categories, isLoading } = useQuery(categoriesQuery(true));
  const reorderMutation = useMutation(reorderCategoryMutation());
  const deleteMutation = useMutation(deleteCategoryMutation());
  const postMutation = useMutation(postCategoryMutation());

  const [isCreating, setIsCreating] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  if (isLoading || !categories) {
    return null;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = categories.findIndex((item) => item.id === active.id);
      const newIndex = categories.findIndex((item) => item.id === over?.id);

      const movedCategories = arrayMove(categories, oldIndex, newIndex).map(
        (category, index) => {
          const newCategory: SaleCategory = {
            ...category,
            index,
          };
          return newCategory;
        }
      );

      reorderMutation.mutate(movedCategories);
    }
  };

  const handleDeleteCategory = (id: string) => {
    deleteMutation.mutate(id);
  };

  const handleCreateCategory = () => {
    if (newCategoryName.trim()) {
      const newCategory: SaleCategory = {
        id: uuidV4(),
        index: categories.length,
        title: newCategoryName,
      };
      setNewCategoryName('');
      setIsCreating(false);
      postMutation.mutate(newCategory);
    }
  };

  const handleBlur = () => {
    if (!newCategoryName.trim()) {
      setIsCreating(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-4 p-4">
      <Title>CATÉGORIES</Title>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={categories}
            strategy={verticalListSortingStrategy}
          >
            {categories.map((category) => (
              <DraggableCategoryItem
                key={category.id}
                category={category}
                onDelete={handleDeleteCategory}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div className="flex flex-1 gap-x-2">
        {isCreating ? (
          <div className="flex flex-1 items-center gap-x-2 rounded-lg border border-bluePrimary bg-background p-2">
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateCategory();
                }
              }}
              className="flex-1 border-none outline-none"
              placeholder="Entrez le nom de la catégorie"
              autoFocus
            />
            <button
              onClick={handleCreateCategory}
              className="text-bluePrimary hover:text-blueSecondary"
            >
              <Check className="transition-transform hover:scale-125" />
            </button>
          </div>
        ) : (
          <Button
            size="lg"
            className="flex flex-1 gap-x-3 border border-bluePrimary bg-background text-bluePrimary hover:border-0 hover:bg-blueSecondary hover:text-white"
            onClick={() => setIsCreating(true)}
          >
            <CirclePlus strokeWidth={1} />
            <p className="mr-2 font-bold">Créer une catégorie</p>
          </Button>
        )}
      </div>
    </div>
  );
};

const Title = styled.h1`
  text-align: center;
  font-size: 15px;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.primary};
`;
