import { queryOptions } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { SaleCategory } from '../../types';

type CategoryQueryParams = {
  signal?: AbortSignal;
  onlyDatabaseCategories: boolean;
};

const getCategories = async ({
  signal,
  onlyDatabaseCategories,
}: CategoryQueryParams) => {
  const { data } = await axios.get<AxiosResponse<SaleCategory[]>>(
    '/v1/category',
    { signal, params: { onlyDatabaseCategories } }
  );

  return data.data;
};

// if onlyDatabaseCategories is set to true it won't add (allSales, upcomingSales, lastDay).
// in the response. only categories as in their table.
export const categoriesQuery = (onlyDatabaseCategories: boolean) => {
  return queryOptions({
    queryKey: [QueryKeys.CATEGORIES, onlyDatabaseCategories],
    queryFn: ({ signal }) => getCategories({ signal, onlyDatabaseCategories }),
  });
};
