export interface Color {
  mono: {
    black: string;
    white: string;
    success: string;
    danger: string;
  };
  beige: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  blue: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
  };
  white: {
    primary: string;
  };
  grey: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  orange: {
    primary: string;
    overlay: string;
  };
}

const colors: Color = {
  mono: {
    black: '#111111',
    white: '#FFFFFF',
    success: '#00B74A',
    danger: '#F93154',
  },
  beige: {
    primary: '#FCF5EE',
    secondary: '#F4F0E9',
    tertiary: '#F4EDE6',
  },
  blue: {
    primary: '#002961',
    secondary: '#5588CD',
    tertiary: '#C4D2E4',
    quaternary: '#315A92',
  },
  white: {
    primary: '#FFFFFF',
  },
  grey: {
    primary: '#C7C5C5',
    secondary: '#949191',
    tertiary: '#C4C4C4',
  },
  orange: {
    primary: '#FF6943',
    overlay: '#F9E8DF',
  },
};
export default colors;
