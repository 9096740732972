import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { FormFieldInput } from '../form/fields/FormFieldInput';
import { OngoingSaleFormSchema } from '../form/schema/OngoingSaleFormSchema';

export const SaleOptionDiscount = () => {
  const { getFieldState, resetField } = useFormContext<OngoingSaleFormSchema>();
  const { error } = getFieldState('discount');

  return (
    <Container>
      <p className="font-workSansSemiBold text-bluePrimary">
        Réduction de la vente %
      </p>
      <div className="flex gap-x-8">
        <FormFieldInput
          fieldName="discount.upTo"
          type="number"
          label="jusqu’à"
          classNames={{
            container: 'w-full flex flex-col items-center space-y-0 gap-y-2',
            label: 'font-workSans text-bluePrimary text-sm',
            input: 'w-full bg-beigeSecondary',
          }}
          onChange={() => resetField('discount.allAt', { defaultValue: null })}
        />
        <p className="font-workSansSemiBold text-bluePrimary">ou</p>
        <FormFieldInput
          fieldName="discount.allAt"
          type="number"
          label="tout à"
          classNames={{
            container: 'w-full flex flex-col items-center space-y-0 gap-y-2',
            label: 'font-workSans text-bluePrimary text-sm',
            input: 'w-full bg-beigeSecondary',
          }}
          onChange={() => resetField('discount.upTo', { defaultValue: null })}
        />
      </div>
      {error && (
        <p className="animate-shake text-sm text-destructive">
          {error.root?.message}
        </p>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
`;
