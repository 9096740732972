import React from 'react';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { CATEGORY_ALL_SALLES, CATEGORY_UPCOMING_SALLES } from '../constants';
import { useHomePageContext } from '../context/HomepageContext';

type SaleCreatorBlockProps = {
  isDragging: boolean;
  title: string;
  height?: string;
  width?: string;
};

const SaleCreatorBlock: React.FC<SaleCreatorBlockProps> = ({
  isDragging,
  title,
  height = '100px',
  width,
}) => {
  const context = useHomePageContext();
  const isActive =
    context.selectedCategory === CATEGORY_ALL_SALLES ||
    context.selectedCategory === CATEGORY_UPCOMING_SALLES;

  let backgroundColor = colors.blue.primary;
  let cursor = 'grab';

  if (isDragging) {
    backgroundColor = 'lightgreen';
    cursor = 'grabbing';
  } else if (!isActive) {
    backgroundColor = colors.grey.primary;
    cursor = 'not-allowed';
  }

  return (
    <div
      style={{
        width,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: backgroundColor,
        cursor,
        opacity: isDragging ? 0.5 : 1,
        userSelect: 'none',
        color: colors.mono.white,
        fontFamily: fonts.WorkSans.regular,
        fontSize: '13px',
        borderRadius: '8px',
        height,
      }}
    >
      {title}
    </div>
  );
};

export default SaleCreatorBlock;
