import { useQuery } from '@tanstack/react-query';
import { SheetTrigger } from '../../../shadcn/components/ui/sheet';
import { Tabs, TabsContent } from '../../../shadcn/components/ui/tabs';
import {
  CATEGORY_ALL_SALLES,
  CATEGORY_UPCOMING_SALLES,
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
} from '../constants';
import { useHomePageContext } from '../context/HomepageContext';
import { categoriesQuery } from '../rest/get/getCategories';
import { CategoriesList } from './CategoriesList';
import { DroppableSalesList } from './drag_n_drop/DroppableSalesList';
import { OngoingSaleBlock } from './OngoingSaleBlock';

export const HomepagePreview = () => {
  const context = useHomePageContext();
  const { data: categories } = useQuery(categoriesQuery(false));

  const draggableOngoingSales = context.getDraggableOngoingSales();
  return (
    <Tabs
      defaultValue={CATEGORY_ALL_SALLES}
      style={{
        width: 390,
        cursor: 'default',
      }}
      onValueChange={(value) => context.setSelectedCategory(value)}
    >
      <CategoriesList />

      <TabsContent
        value={CATEGORY_ALL_SALLES}
        onClick={() => context.setIsFormOpen(true)}
      >
        <DroppableSalesList
          dndContainerId={DND_ONGOING_SALE_PREVIEW_ID}
          draggableSales={context.getDraggableOngoingSales()}
        />
      </TabsContent>

      <TabsContent
        value={CATEGORY_UPCOMING_SALLES}
        onClick={() => context.setIsFormOpen(true)}
      >
        <DroppableSalesList
          dndContainerId={DND_UPCOMING_SALE_PREVIEW_ID}
          draggableSales={context.getDraggableUpcomingSales()}
        />
      </TabsContent>

      {categories?.map((category) => (
        <TabsContent key={category.id} value={category.id}>
          <SheetTrigger className="w-full cursor-not-allowed bg-white">
            {draggableOngoingSales
              .filter(({ sale }) =>
                sale.categories.some((c) => c.id === category.id)
              )
              .map(({ sale }) => (
                <div className="mb-1 h-[260px] select-none" key={sale.id}>
                  <OngoingSaleBlock ongoingSale={sale} key={sale.id} />
                </div>
              ))}
          </SheetTrigger>
        </TabsContent>
      ))}
    </Tabs>
  );
};
