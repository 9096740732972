import { CSSProperties } from 'react';
import styled from 'styled-components';
import ImageOrVideo from '../../../components/miscellaneous/ImageOrVideo';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { renderSaleTimeRemaining } from '../utils/renderTimeRemaining';
import { OngoingSale } from '../types';

type OngoingSaleBlockProps = {
  ongoingSale?: OngoingSale;
};

export const OngoingSaleBlock = ({ ongoingSale }: OngoingSaleBlockProps) => {
  const url = ongoingSale?.homepageImages?.[0]?.url;
  const imageStyle: CSSProperties = {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  };

  return (
    <Container>
      {url ? (
        <ImageOrVideo url={url} style={imageStyle} />
      ) : (
        <img
          src={require('../../../assets/image_placeholder_sale.png')}
          alt="placeholder"
          style={imageStyle}
        />
      )}
      <TopContainer>
        {ongoingSale?.dateStop && (
          <Text>{renderSaleTimeRemaining(ongoingSale.dateStop)}</Text>
        )}
        {ongoingSale?.discountAllAt && (
          <TextBold>{`Tout à -${ongoingSale.discountAllAt} %`}</TextBold>
        )}
        {ongoingSale?.discountUpTo && (
          <TextBold>{`Jusqu'à -${ongoingSale.discountUpTo} %`}</TextBold>
        )}
      </TopContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const TopContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  top: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.46262254901960786) 57%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const Text = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 11px;
  color: ${colors.mono.white};
`;

const TextBold = styled.div`
  font-family: ${fonts.WorkSans.semiBold};
  font-size: 11px;
  color: ${colors.mono.white};
`;
