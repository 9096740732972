import { UpcomingSaleFormSchema } from '../components/form/schema/UpcomingSaleFormSchema';
import { UpcomingSale, UpcomingSaleDTO } from '../types';

export const createUpcomingSaleDTOFromSchema = (
  data: UpcomingSaleFormSchema,
  selectedSale: UpcomingSale
) => {
  const baseDTO: UpcomingSaleDTO = {
    id: selectedSale.id < 0 ? undefined : selectedSale.id,
    index: selectedSale.index || 0,
    isOnlyDisplayedToDevs: data.isOnlyDisplayedToDevs,
    lockHomepagePosition: data.lockHomepagePosition,
    // remove once legacy sales are removed
    isLegacySale: selectedSale.isLegacySale,
    dateStart: data.dateStart?.toISOString(),
    dateStop: data.dateStop?.toISOString(),
    blockType: selectedSale.blockType,
    description: data.description,
    additionalImages: data.additionalImages,
    homepageImage: data.homepageImage,
    productVendor: data.productVendor,
  };

  return baseDTO;
};
