import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  CATEGORY_ALL_SALLES,
  CATEGORY_UPCOMING_SALLES,
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
  SALE_BLOCK_HEIGHT,
} from '../../constants';
import { useHomePageContext } from '../../context/HomepageContext';
import { OngoingSaleBlock } from '../OngoingSaleBlock';
import { DraggableOngoingSale, DraggableUpcomingSale } from '../../types';
import { useEffect, useState } from 'react';
import { isOngoingSale } from '../../../../utils/typguards/isOngoingSale';
import { isUpcomingSale } from '../../../../utils/typguards/isUpcomingSale';
import { UpcomingSaleBlock } from '../UpcomingSaleBlock';

export type DraggableSaleItemProps = {
  dndContainerId:
    | typeof DND_ONGOING_SALE_PREVIEW_ID
    | typeof DND_UPCOMING_SALE_PREVIEW_ID;
  draggable: DraggableOngoingSale | DraggableUpcomingSale;
};

export const DraggableSaleItem = ({
  draggable,
  dndContainerId,
}: DraggableSaleItemProps) => {
  const context = useHomePageContext();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isOver,
    isDragging,
  } = useSortable({
    id: draggable.id ?? -1,
    disabled:
      context.selectedCategory !== CATEGORY_ALL_SALLES &&
      context.selectedCategory !== CATEGORY_UPCOMING_SALLES,
    data: {
      containerId: dndContainerId,
    },
  });

  const { sale } = draggable;
  const [isSelected, setIsSelected] = useState(
    context.selectedSale?.id === sale.id
  );
  const [delayedIsSelected, setDelayedIsSelected] = useState(isSelected);

  // For the custom transition to work properly with dnd's transition.
  useEffect(() => {
    if (context.selectedSale?.id === sale.id) {
      setIsSelected(true);
      setDelayedIsSelected(true);
    } else {
      setIsSelected(false);
      const timer = setTimeout(() => {
        setDelayedIsSelected(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [context.selectedSale, sale.id]);

  let cursor = 'grab';

  if (isDragging) {
    cursor = 'grabbing';
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        position: isDragging || delayedIsSelected ? 'relative' : 'inherit',
        zIndex: isDragging || delayedIsSelected ? 10 : 0,
        userSelect: 'none',
        marginBottom: '4px',
        height: SALE_BLOCK_HEIGHT,
        transform:
          isSelected && !isOver
            ? 'scale(1.1)'
            : CSS.Transform.toString(transform),
        transition: delayedIsSelected ? 'transform 300ms ease' : transition,
        cursor,
      }}
      onClick={() => context.setSelectedSale(sale)}
    >
      {isOngoingSale(sale) ? (
        <OngoingSaleBlock ongoingSale={sale} />
      ) : isUpcomingSale(sale) ? (
        <UpcomingSaleBlock upcomingSale={sale} />
      ) : undefined}
    </div>
  );
};
