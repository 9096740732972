import { GearIcon } from '@radix-ui/react-icons';
import { VisuallyHidden } from 'rsuite';
import { Separator } from '../../../../../shadcn/components/separator';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../shadcn/components/ui/dialog';
import { SheetDescription } from '../../../../../shadcn/components/ui/sheet';
import { FormFieldSwitch } from '../../form/fields/FormFieldSwitch';

export const SaleOptionAdvanced = () => {
  return (
    <Dialog>
      <DialogTrigger>
        <GearIcon className="h-4 w-4 cursor-pointer transition-transform duration-500 hover:rotate-180" />
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Paramètres avancés.</DialogTitle>
          {/* <VisuallyHidden> needed to remove a warning in the console */}
          <VisuallyHidden>
            <SheetDescription />
          </VisuallyHidden>
        </DialogHeader>
        <Separator />

        <FormFieldSwitch
          fieldName="isOnlyDisplayedToDevs"
          label="Afficher uniquement aux comptes développeurs"
        />
      </DialogContent>
    </Dialog>
  );
};
