import axios from 'axios';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';

const postMediaFile = async (data: { url: string; file: File }) => {
  const { url, file } = data;
  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      // To put the file in the bucket, we need to remove the Authorization header
      // otherwise, we will get a 400 error by AWS.
      Authorization: undefined,
    },
  });
};

export const mediaFileMutation = (onSuccess: () => void) => {
  return mutationOptions<void, Error, { url: string; file: File }>({
    mutationFn: postMediaFile,
    onSuccess,
  });
};
