import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
} from '../../../../shadcn/components/ui/form';
import { TabsTrigger } from '../../../../shadcn/components/ui/tabs';
import { SaleDataType } from '../../types';
import { OngoingSaleFormSchema } from '../form/schema/OngoingSaleFormSchema';

export const SaleOptionTypes = () => {
  const { control } = useFormContext<OngoingSaleFormSchema>();

  return (
    <FormField
      control={control}
      name="dataType"
      render={({ field }) => (
        <FormItem className="flex-1">
          <FormControl>
            <div className="grid h-auto grid-cols-2 gap-2">
              {Object.values(SaleDataType).map((dataType) => (
                <TabsTrigger
                  key={dataType}
                  value={dataType}
                  onClick={() => field.onChange(dataType)}
                  className="flex-1 bg-beigeTertiary font-bold data-[state=active]:text-blueSecondary"
                >
                  {dataType}
                </TabsTrigger>
              ))}
            </div>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
