import { useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';
import colors from '../../../../styles/colors.styles';
import fonts from '../../../../styles/fonts.styles';
import {
  CATEGORY_ALL_SALLES,
  CATEGORY_UPCOMING_SALLES,
  DND_SALE_CREATOR_ID,
} from '../../constants';
import { useHomePageContext } from '../../context/HomepageContext';
import { DraggableSaleCreator } from './DraggableSaleCreator';
import { DraggableCreationBlock } from '../../types';

type SaleCreationListProps = {
  predicate: (draggable: DraggableCreationBlock) => boolean;
};

export const SaleCreationList = ({ predicate }: SaleCreationListProps) => {
  const context = useHomePageContext();
  const { setNodeRef } = useDroppable({
    id: DND_SALE_CREATOR_ID,
    disabled:
      context.selectedCategory !== CATEGORY_UPCOMING_SALLES &&
      context.selectedCategory !== CATEGORY_ALL_SALLES,
  });

  return (
    <Container>
      <Title>BLOCS À AJOUTER</Title>
      <div
        ref={setNodeRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 20,
          width: 250,
        }}
      >
        {context.draggableCreationBlocks.filter(predicate).map((draggable) => (
          <DraggableSaleCreator draggable={draggable} key={draggable.id} />
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 15px;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.secondary};
`;
