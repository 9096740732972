import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Menu, X } from 'lucide-react';
import { useState } from 'react';
import { SaleCategory } from '../../types';

type DraggableCategoryProps = {
  category: SaleCategory;
  onDelete: (id: string) => void;
};

export const DraggableCategoryItem = ({
  category,
  onDelete,
}: DraggableCategoryProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: category.id });

  const [isHovered, setIsHovered] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        position: isDragging ? 'relative' : 'inherit',
        zIndex: isDragging ? 1000 : 0,
        cursor: 'default',
        backgroundColor: isHovered ? '#d70000' : 'white',
      }}
      {...attributes}
      className="border-blue-500 mb-1 flex items-center justify-between rounded-lg border p-4 shadow-lg transition-colors duration-200"
    >
      <Menu
        {...listeners}
        className={`mr-4 ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
      />
      <span className="flex-1 font-workSansMedium text-bluePrimary">
        {category.title}
      </span>
      <button
        onClick={() => onDelete(category.id)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="rounded-lg p-1 transition-transform duration-200 hover:scale-150"
      >
        <X color="#002961" />
      </button>
    </div>
  );
};
