import {
  CategoryAllSales,
  CategoryUpcomingSales,
  OngoingSale,
  SaleBlockType,
  SaleCreationBlock,
  SaleDataType,
  UpcomingSale,
} from './types';

export const AWS_IMAGES_URL = 'https://img.thebradery.com/';

export const SALE_CREATION_BLOCKS: SaleCreationBlock[] = [
  {
    index: 0,
    title: 'Bloc Image/Gif/Video',
    blockType: SaleBlockType.NORMAL,
  },
  {
    index: 1,
    title: 'Bloc Carousel',
    blockType: SaleBlockType.CAROUSEL,
  },
];

export const DEFAULT_ONGOING_SALE: Omit<
  OngoingSale,
  'id' | 'index' | 'blockType'
> = {
  dataType: SaleDataType.COLLECTION,
  isDisplayedInAllSales: false,
  isOnlyDisplayedToDevs: false,
  // remove once legacy sales are removed
  isLegacySale: false,
  collection: {},
  categories: [],
  homepageImages: [],
};

export const DEFAULT_UPCOMING_SALE: Omit<
  UpcomingSale,
  'id' | 'index' | 'blockType'
> = {
  isOnlyDisplayedToDevs: false,
  // remove once legacy sales are removed
  isLegacySale: false,
  lockHomepagePosition: false,
};

export const DND_SALE_CREATOR_ID = 'sales-creator';
export const DND_ONGOING_SALE_PREVIEW_ID = 'ongoing-sales-list-preview';
export const DND_UPCOMING_SALE_PREVIEW_ID = 'upcoming-sales-list-preview';

export const CATEGORY_ALL_SALLES: CategoryAllSales = 'allSales';
export const CATEGORY_UPCOMING_SALLES: CategoryUpcomingSales = 'upcomingSales';

export const SALE_BLOCK_HEIGHT = 260;
