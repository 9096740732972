import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';

interface SubButtonNavbarProps {
  type: 'link' | 'action';
  text: string;
  to?: any;
  onClick?: () => void;
}

const SubButtonNavbar: React.FC<SubButtonNavbarProps> = ({
  text,
  to,
  type,
  onClick,
}) => {
  const location = useLocation();

  if (type === 'link') {
    return (
      <Link to={to}>
        <Span isSelected={location.pathname === to}>{text}</Span>
      </Link>
    );
  } else {
    return (
      <Span isSelected={false} onClick={onClick}>
        {text}
      </Span>
    );
  }
};

export default SubButtonNavbar;

const Span = styled.span<{ isSelected: boolean }>`
  color: ${(props) =>
    props.isSelected ? colors.orange.primary : colors.blue.primary};
  font-size: 14px;
  font-family: ${fonts.WorkSans.regular};
  cursor: pointer;

  &:hover {
    color: ${colors.orange.primary};
  }
`;
