import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { utcToLocalDate } from '../../../utils/date';

interface DateInputProps extends HTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  date?: Date;
  hideTime?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  name,
  onChange,
  date,
  hideTime,
  ...props
}) => {
  const formatDate = (): string => {
    const localDate = utcToLocalDate(date, hideTime);

    return localDate.substring(0, 16) || '';
  };

  return (
    <Container {...props}>
      {label && <Label>{label}</Label>}

      {hideTime ? (
        <DateOnlyInput name={name} onChange={onChange} value={formatDate()} />
      ) : (
        <DateTimeInput name={name} onChange={onChange} value={formatDate()} />
      )}
    </Container>
  );
};

export default DateInput;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 12px;
  color: ${colors.blue.primary};
  width: 60px;
  text-align: right;
  margin: 0 8px 0 20px;
`;

const DateTimeInput = styled.input.attrs({ type: 'datetime-local' })`
  box-sizing: border-box;
  width: calc(100%);
  background-color: ${colors.beige.tertiary};
  margin: 5px 22px 5px 0;
  border: none;
  border-radius: 4px;
  padding: 11px 11px 11px 11px;

  &:focus {
    outline: none;
  }
`;

const DateOnlyInput = styled.input.attrs({ type: 'date' })`
  box-sizing: border-box;
  width: calc(100%);
  background-color: ${colors.beige.tertiary};
  margin: 5px 0 5px 0;
  border: none;
  border-radius: 4px;
  padding: 11px 11px 11px 11px;

  &:focus {
    outline: none;
  }
`;
