import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors.styles';

const Separator = () => {
  return <Seperator />;
};

const Seperator = styled.div`
  height: 100vw;
  width: 3px;
  background: ${colors.beige.tertiary};
`;

export default Separator;
