import axios from 'axios';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import {
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
} from '../../constants';
import { DNDContainerType, SaleIdIndexDTO } from '../../types';

type MutationVariables = {
  dto: SaleIdIndexDTO[];
  containerId: DNDContainerType;
};

const reorderOngoingSale = async (dto: SaleIdIndexDTO[]) => {
  await axios.post('/v1/ongoing-sales/ordering', dto);
};

const reorderUpcomingSale = async (dto: SaleIdIndexDTO[]) => {
  await axios.post('/v1/upcoming-sales/ordering', dto);
};

const reorderSale = async ({
  dto,
  containerId,
}: MutationVariables): Promise<MutationVariables> => {
  if (containerId === DND_ONGOING_SALE_PREVIEW_ID) {
    await reorderOngoingSale(dto);
  } else if (containerId === DND_UPCOMING_SALE_PREVIEW_ID) {
    await reorderUpcomingSale(dto);
  }
  return { dto, containerId };
};

export const reorderSaleMutation = () => {
  return mutationOptions({
    mutationFn: reorderSale,
    onSuccess: updateCache,
  });
};

// Cache invalidation after reordering
const updateCache = ({ containerId }: MutationVariables) => {
  return () => {
    const queryKey =
      containerId === DND_ONGOING_SALE_PREVIEW_ID
        ? QueryKeys.ONGOING_SALES
        : QueryKeys.UPCOMING_SALES;

    queryClient.invalidateQueries({ queryKey: [queryKey] });
  };
};
