import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Separator } from '../../../../shadcn/components/separator';
import { Form } from '../../../../shadcn/components/ui/form';
import { isUpcomingSale } from '../../../../utils/typguards/isUpcomingSale';
import { useHomePageContext } from '../../context/HomepageContext';
import { deleteUpcomingSaleMutation } from '../../rest/delete/deleteUpcomingSale';
import { postUpcomingSaleMutation } from '../../rest/post/postUpcomingSale';
import {
  UPCOMING_SALE_FORM_SCHEMA,
  UpcomingSaleFormSchema,
} from './schema/UpcomingSaleFormSchema';
import { UpcomingSale } from '../../types';
import { createUpcomingSaleDTOFromSchema } from '../../utils/createUpcomingSaleDTOFromSchema';
import { SaleOptionDates } from '../sale_options/SaleOptionDates';
import { SaleOptionMediasUploader } from '../sale_options/SaleOptionMediasUploader';
import { SaleFormFooter } from './SaleFormFooter';
import { SaleFormHeader } from './SaleFormHeader';
import { reorderSaleMutation } from '../../rest/post/postReorderingSale';
import { DND_UPCOMING_SALE_PREVIEW_ID } from '../../constants';
import { UpcomingSaleOptionPage } from '../sale_options/UpcomingSaleOptionPage';
import { UpcomingSaleOptionProductVendor } from '../sale_options/UpcomingSaleOptionProductVendor';
import { FormFieldSwitch } from './fields/FormFieldSwitch';

type UpcomingSaleFormProps = {
  upcomingSale: UpcomingSale;
};

export const UpcomingSaleForm = ({ upcomingSale }: UpcomingSaleFormProps) => {
  const context = useHomePageContext();
  const salePostMutation = useMutation(postUpcomingSaleMutation());
  const saleDeleteMutation = useMutation(
    deleteUpcomingSaleMutation(upcomingSale.isLegacySale)
  );
  const reorderPostMutation = useMutation(reorderSaleMutation());

  const [isDelete, setIsDelete] = useState(false);

  const form = useForm<UpcomingSaleFormSchema>({
    resolver: zodResolver(UPCOMING_SALE_FORM_SCHEMA),
    defaultValues: {
      lockHomepagePosition: upcomingSale.lockHomepagePosition,
      homepageImage: {
        url: upcomingSale.homepageImage?.url,
      },
      dateStart: upcomingSale.dateStart,
      dateStop: upcomingSale.dateStop,
      productVendor: upcomingSale.productVendor,
      description: upcomingSale.description,
      additionalImages: {
        leftUrl: upcomingSale.additionalImages?.leftUrl,
        rightUrl: upcomingSale.additionalImages?.rightUrl,
      },
      isOnlyDisplayedToDevs: upcomingSale.isOnlyDisplayedToDevs,
    },
  });

  useEffect(() => {
    context.setIsFormDirty(form.formState.isDirty);
  }, [context, form.formState.isDirty]);

  const onSubmit: SubmitHandler<UpcomingSaleFormSchema> = async (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    // This prevents the page from reloading when the form is submitted
    e?.preventDefault();

    if (!isUpcomingSale(context.selectedSale)) {
      return;
    }

    const onMutationSuccess = () => {
      context.setIsFormOpen(false);
      setTimeout(() => context.setSelectedSale(undefined), 150);

      // The sale was added, so we need to reorder the other ones
      if (upcomingSale.id < 0 || isDelete) {
        reorderPostMutation.mutate({
          containerId: DND_UPCOMING_SALE_PREVIEW_ID,
          dto: context.draggableAllSales
            .filter(({ sale }) => sale.id !== upcomingSale.id)
            .map(({ sale }, index) => ({
              id: sale.id,
              isLegacySale: sale.isLegacySale,
              index: isDelete ? index : sale.index,
            })),
        });
      }
    };

    if (isDelete) {
      saleDeleteMutation.mutate(upcomingSale.id, {
        onSuccess: onMutationSuccess,
      });
    } else {
      const dto = createUpcomingSaleDTOFromSchema(data, context.selectedSale);
      salePostMutation.mutate(dto, {
        onSuccess: onMutationSuccess,
      });
    }
  };

  const onFormError: SubmitErrorHandler<UpcomingSaleFormSchema> = (data) => {};

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onFormError)}
        className="space-y-4"
      >
        <SaleFormHeader />
        <FullWidthSeparator />
        <div className="flex flex-col gap-y-8">
          <SaleOptionMediasUploader
            blockType={upcomingSale.blockType}
            isUpcomingSale
          />
          <FormFieldSwitch
            fieldName="lockHomepagePosition"
            description="Verouiller la position dans la homepage"
            classNames={{
              description: 'font-workSans text-bluePrimary',
            }}
          />
        </div>

        <FullWidthSeparator />
        <SaleOptionDates />
        <FullWidthSeparator />
        <UpcomingSaleOptionProductVendor />
        <FullWidthSeparator />
        <UpcomingSaleOptionPage />
        <FullWidthSeparator />

        <SaleFormFooter
          isFromBackend={(upcomingSale.id ?? -1) >= 0}
          setIsDelete={setIsDelete}
        />
      </form>
    </Form>
  );
};

const FullWidthSeparator = styled(Separator)`
  height: 0.25em;
  width: calc(100% + 4rem);
  margin-left: -2rem;
`;
