import axios, { AxiosResponse } from 'axios';
import { queryOptions } from '@tanstack/react-query';
import { QueryKeys } from '../QueryKeys';

type S3SignedURL = {
  url: string;
  path: string;
};

const getS3SignedURL = async (fileName: string, signal?: AbortSignal) => {
  const { data } = await axios.get<AxiosResponse<S3SignedURL>>(
    '/aws/getS3SignedURL',
    {
      signal,
      params: {
        extension: fileName.slice(fileName.lastIndexOf('.') + 1),
      },
    }
  );

  return data.data;
};

export const s3SignedURLQuery = (fileName: string | undefined) => {
  return queryOptions({
    queryKey: [QueryKeys.S3_SIGNED_URL, fileName],
    queryFn: ({ signal }) =>
      fileName ? getS3SignedURL(fileName, signal) : undefined,
    enabled: !!fileName,
  });
};
