import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isDraggableOngoingSale } from '../../../utils/typguards/isDraggableOngoingSale';
import { isDraggableOngoingSaleArray } from '../../../utils/typguards/isDraggableOngoingSaleArray';
import { isDraggableUpcomingSale } from '../../../utils/typguards/isDraggableUpcomingSale';
import { isDraggableUpcomingSaleArray } from '../../../utils/typguards/isDraggableUpcomingSaleArray';
import { isOngoingSale } from '../../../utils/typguards/isOngoingSale';
import {
  CATEGORY_ALL_SALLES,
  CATEGORY_UPCOMING_SALLES,
  DEFAULT_ONGOING_SALE,
  DEFAULT_UPCOMING_SALE,
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_SALE_CREATOR_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
  SALE_CREATION_BLOCKS,
} from '../constants';
import { categoriesQuery } from '../rest/get/getCategories';
import { ongoingSalesQuery } from '../rest/get/getOngoingSales';
import { upcomingSalesQuery } from '../rest/get/getUpcomingSales';
import {
  CategoryAllSales,
  CategoryUpcomingSales,
  DNDContainerType,
  DraggableCreationBlock,
  DraggableOngoingSale,
  DraggableUpcomingSale,
  OngoingSale,
  RearrangedSales,
  UpcomingSale,
} from '../types';

type HomepageContextType = {
  // Local state
  setSelectedCategory: (
    category: string | CategoryAllSales | CategoryUpcomingSales
  ) => void;
  selectedCategory: string | CategoryAllSales | CategoryUpcomingSales;
  setSelectedSale: (sale: OngoingSale | UpcomingSale | undefined) => void;
  selectedSale: OngoingSale | UpcomingSale | undefined;
  setDraggedCreationBlock: (block: DraggableCreationBlock | undefined) => void;
  draggedCreationBlock: DraggableCreationBlock | undefined;
  setDraggedOngoingSale: (sale: DraggableOngoingSale | undefined) => void;
  draggedOngoingSale: DraggableOngoingSale | undefined;
  setDraggedUpcomingSale: (sale: DraggableUpcomingSale | undefined) => void;
  draggedUpcomingSale: DraggableUpcomingSale | undefined;
  setIsFormDirty: (isDirty: boolean) => void;
  isFormDirty: boolean;
  setIsFormOpen: (isOpen: boolean) => void;
  isFormOpen: boolean;

  // draggables
  draggableAllSales: DraggableOngoingSale[] | DraggableUpcomingSale[];
  draggableCreationBlocks: DraggableCreationBlock[];

  addToDraggableAllSales: (
    arrayId: DNDContainerType,
    draggable: DraggableCreationBlock,
    index?: number
  ) => void;
  generateDraggableCreationBlocks: () => void;
  removeFromDraggables: (
    arrayId: DNDContainerType,
    elementId: UniqueIdentifier
  ) => void;
  rearrangeSalesDraggables: (
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier
  ) => RearrangedSales | undefined;

  // Sale modifiers
  setSelectedOngoingSaleProductName: (productName: string) => void;
  setSelectedOngoingSaleCollectionName: (collectionName: string) => void;

  // Sale getters
  getDraggableOngoingSales: () => DraggableOngoingSale[];
  getDraggableUpcomingSales: () => DraggableUpcomingSale[];
};

const HomepageContext = createContext<HomepageContextType | undefined>(
  undefined
);

export const useHomePageContext = () => {
  const context = useContext(HomepageContext);
  if (context === undefined) {
    throw new Error(
      'useHomePageContext must be used within a HomepageContextProvider'
    );
  }
  return context;
};

type HomepageProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const HomepageContextProvider = ({
  children,
}: HomepageProviderProps) => {
  const [selectedCategory, setSelectedCategory] = useState<
    string | CategoryAllSales | CategoryUpcomingSales
  >(CATEGORY_ALL_SALLES);
  const [selectedSale, setSelectedSale] = useState<
    OngoingSale | UpcomingSale
  >();

  const [draggableAllSales, setDraggableAllSales] = useState<
    DraggableOngoingSale[] | DraggableUpcomingSale[]
  >([]);
  const [draggableCreationBlocks, setDraggableCreationBlocks] = useState<
    DraggableCreationBlock[]
  >(
    SALE_CREATION_BLOCKS.map((block) => ({
      id: uuidv4(),
      creationBlock: block,
    }))
  );
  const [draggedCreationBlock, setDraggedCreationBlock] =
    useState<DraggableCreationBlock>();
  const [draggedOngoingSale, setDraggedOngoingSale] =
    useState<DraggableOngoingSale>();
  const [draggedUpcomingSale, setDraggedUpcomingSale] =
    useState<DraggableUpcomingSale>();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { data: categories } = useQuery(categoriesQuery(false));
  // Data pulled wether from cache or from the server
  // is immutable to protect the cache integrity
  const { data: ongoingSalesOfCategory } = useQuery(
    ongoingSalesQuery(selectedCategory, categories ?? [])
  );
  // Data pulled wether from cache or from the server
  // is immutable to protect the cache integrity
  const { data: upcomingSales } = useQuery(
    upcomingSalesQuery(selectedCategory)
  );

  useEffect(() => {
    if (!categories) {
      return;
    }

    if (selectedCategory === CATEGORY_UPCOMING_SALLES && upcomingSales) {
      setDraggableAllSales(
        upcomingSales.map((sale) => ({ id: uuidv4(), sale }))
      );
    } else if (
      selectedCategory !== CATEGORY_UPCOMING_SALLES &&
      ongoingSalesOfCategory
    ) {
      setDraggableAllSales(
        ongoingSalesOfCategory.map((sale) => ({ id: uuidv4(), sale }))
      );
    }
  }, [categories, ongoingSalesOfCategory, upcomingSales, selectedCategory]);

  /*************************** GETTERS ***************************/

  const getDraggableOngoingSales = useCallback(() => {
    return draggableAllSales.filter((draggable) =>
      isDraggableOngoingSale(draggable)
    );
  }, [draggableAllSales]);

  const getDraggableUpcomingSales = useCallback(() => {
    return draggableAllSales.filter((draggable) =>
      isDraggableUpcomingSale(draggable)
    );
  }, [draggableAllSales]);

  /*************************** SETTERS ***************************/

  const setSelectedOngoingSaleCollectionName = useCallback(
    (collectionName: string) => {
      setSelectedSale((sale) => {
        if (!isOngoingSale(sale)) {
          return sale;
        }

        return {
          ...sale,
          collection: {
            ...sale.collection,
            name: collectionName,
          },
        };
      });
    },
    []
  );

  const setSelectedOngoingSaleProductName = useCallback(
    (productName: string) => {
      setSelectedSale((sale) => {
        if (!isOngoingSale(sale)) {
          return sale;
        }

        return {
          ...sale,
          product: {
            ...sale.product,
            name: productName,
          },
        };
      });
    },
    []
  );

  const getSmallestOngoingSaleId = useCallback((): number => {
    if (draggableAllSales.length === 0) {
      return -1;
    }

    const smallestId = draggableAllSales.reduce((minId, draggable) => {
      const { sale } = draggable;

      if (sale.id === undefined) {
        return minId;
      }

      return sale.id < minId ? sale.id : minId;
    }, 0);

    return smallestId - 1;
  }, [draggableAllSales]);

  const addToDraggableAllSales = useCallback(
    (
      arrayId: DNDContainerType,
      draggable: DraggableCreationBlock,
      index?: number
    ) => {
      const draggableSale: DraggableOngoingSale | DraggableUpcomingSale = {
        id: draggable.id,
        sale: {
          ...(arrayId === DND_UPCOMING_SALE_PREVIEW_ID
            ? DEFAULT_UPCOMING_SALE
            : DEFAULT_ONGOING_SALE),
          id: getSmallestOngoingSaleId(),
          index: index ?? draggableAllSales.length,
          blockType: draggable.creationBlock.blockType,
        },
      };

      setDraggableAllSales((prev) => {
        let newDraggables;
        if (index !== undefined) {
          newDraggables = prev.toSpliced(index, 0, draggableSale);
        } else {
          newDraggables = [...prev, draggableSale];
        }

        // Update the index of each sale
        return newDraggables.map((item, idx) => ({
          ...item,
          sale: {
            ...item.sale,
            index: idx,
          },
        }));
      });
    },
    [getSmallestOngoingSaleId, draggableAllSales]
  );

  const generateDraggableCreationBlocks = useCallback(() => {
    setDraggableCreationBlocks(
      SALE_CREATION_BLOCKS.map((block) => ({
        id: uuidv4(),
        creationBlock: block,
      }))
    );
  }, []);

  const removeFromDraggables = useCallback(
    (arrayId: DNDContainerType, elementId: UniqueIdentifier) => {
      if (
        arrayId === DND_ONGOING_SALE_PREVIEW_ID ||
        arrayId === DND_UPCOMING_SALE_PREVIEW_ID
      ) {
        setDraggableAllSales((prev) => {
          const newDraggables = prev.filter((item) => item.id !== elementId);

          // Update the index of each sale
          return newDraggables.map((item, idx) => ({
            ...item,
            sale: {
              ...item.sale,
              index: idx,
            },
          }));
        });
      } else if (arrayId === DND_SALE_CREATOR_ID) {
        setDraggableCreationBlocks((prev) =>
          prev.filter((item) => item.id !== elementId)
        );
      }
    },
    []
  );

  const rearrangeSalesDraggables = useCallback(
    (activeId: UniqueIdentifier, overId: UniqueIdentifier) => {
      const oldIndex = draggableAllSales.findIndex(
        (draggable) => draggable.id === activeId
      );
      const newIndex = draggableAllSales.findIndex(
        (draggable) => draggable.id === overId
      );
      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      let newDraggables = draggableAllSales;
      if (isDraggableOngoingSaleArray(draggableAllSales)) {
        newDraggables = arrayMove(draggableAllSales, oldIndex, newIndex);
      } else if (isDraggableUpcomingSaleArray(draggableAllSales)) {
        newDraggables = arrayMove(draggableAllSales, oldIndex, newIndex);
      }
      newDraggables.forEach((_, idx) => (newDraggables[idx].sale.index = idx));

      setDraggableAllSales(newDraggables);
      const rearrangedSales: RearrangedSales = {
        activeSale: newDraggables[newIndex].sale,
        moved: newDraggables.map(({ sale }) => ({
          id: sale.id,
          isLegacySale: sale.isLegacySale,
          index: sale.index,
        })),
      };
      return rearrangedSales;
    },
    [draggableAllSales]
  );

  /*************************** CONTEXT VALUE ***************************/

  const value = useMemo(
    () => ({
      setSelectedCategory,
      selectedCategory,
      draggableAllSales,
      draggableCreationBlocks,
      setSelectedSale,
      selectedSale,
      setDraggedCreationBlock,
      draggedCreationBlock,
      setDraggedOngoingSale,
      draggedOngoingSale,
      setDraggedUpcomingSale,
      draggedUpcomingSale,
      setIsFormDirty,
      isFormDirty,
      setIsFormOpen,
      isFormOpen,
      setSelectedOngoingSaleCollectionName,
      setSelectedOngoingSaleProductName,
      getDraggableOngoingSales,
      getDraggableUpcomingSales,
      generateDraggableCreationBlocks,
      addToDraggableAllSales,
      removeFromDraggables,
      rearrangeSalesDraggables,
    }),
    [
      setSelectedCategory,
      selectedCategory,
      draggableAllSales,
      draggableCreationBlocks,
      setSelectedSale,
      selectedSale,
      setDraggedCreationBlock,
      draggedCreationBlock,
      setDraggedOngoingSale,
      draggedOngoingSale,
      setDraggedUpcomingSale,
      draggedUpcomingSale,
      setIsFormDirty,
      isFormDirty,
      setIsFormOpen,
      isFormOpen,
      setSelectedOngoingSaleCollectionName,
      setSelectedOngoingSaleProductName,
      getDraggableOngoingSales,
      getDraggableUpcomingSales,
      generateDraggableCreationBlocks,
      addToDraggableAllSales,
      removeFromDraggables,
      rearrangeSalesDraggables,
    ]
  );

  return (
    <HomepageContext.Provider value={value}>
      {children}
    </HomepageContext.Provider>
  );
};
