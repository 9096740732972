import { UpcomingSaleAdditionnalImages } from '../../pages/homepage/types';

export const isUpcomingSaleAdditionnalImages = (
  obj: any
): obj is UpcomingSaleAdditionnalImages => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'leftUrl' in obj &&
    typeof obj.leftUrl === 'string' &&
    'rightUrl' in obj &&
    typeof obj.rightUrl === 'string'
  );
};
