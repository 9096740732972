import { CSSProperties } from 'react';
import styled from 'styled-components';
import BellOutlinedIcon from '../../../assets/icons/bellOutlined.svg';
import ImageOrVideo from '../../../components/miscellaneous/ImageOrVideo';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { UpcomingSale } from '../types';
import { renderTimeRemainingBeforeTheSaleBegins } from '../utils/renderTimeRemainingBeforeTheSaleBegins';

type UpcomingSaleBlockProps = {
  upcomingSale?: UpcomingSale;
};

export const UpcomingSaleBlock = ({ upcomingSale }: UpcomingSaleBlockProps) => {
  const url = upcomingSale?.homepageImage?.url;
  const imageStyle: CSSProperties = {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  };

  return (
    <Container>
      {url ? (
        <ImageOrVideo url={url} style={imageStyle} />
      ) : (
        <img
          src={require('../../../assets/image_placeholder_sale.png')}
          alt="placeholder"
          style={imageStyle}
        />
      )}
      <TopContainer>
        {upcomingSale?.dateStart && (
          <Text>
            {renderTimeRemainingBeforeTheSaleBegins(upcomingSale.dateStart)}
          </Text>
        )}
      </TopContainer>
      <IconBellWrapper>
        <IconBell src={BellOutlinedIcon} alt="" />
      </IconBellWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
`;

const TopContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  top: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.46262254901960786) 57%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const IconBellWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.beige.primary};
  bottom: 15px;
  right: 15px;
  height: 38px;
  width: 38px;
  border-radius: 8px;
`;

const IconBell = styled.img`
  object-fit: contain;
  height: 22px;
  width: 22px;
`;

const Text = styled.div`
  font-family: ${fonts.WorkSans.regular};
  font-size: 11px;
  color: ${colors.mono.white};
`;
