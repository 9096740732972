import axios, { AxiosResponse } from 'axios';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import { UpcomingSaleDTO } from '../../types';

type PostUpcomingSaleResponse = {
  upcomingSaleId: number;
};

const postUpcomingSale = async (dto: UpcomingSaleDTO) => {
  const { data } = await axios.post<AxiosResponse<PostUpcomingSaleResponse>>(
    '/v1/upcoming-sales',
    dto
  );

  return data.data;
};

export const postUpcomingSaleMutation = () => {
  return mutationOptions({
    mutationFn: postUpcomingSale,
    onSuccess: updateCache,
  });
};

const updateCache = (data: PostUpcomingSaleResponse) => {
  queryClient.invalidateQueries({ queryKey: [QueryKeys.UPCOMING_SALES] });
};
