import { CircleMinus, CirclePlus } from 'lucide-react';
import { useFieldArray } from 'react-hook-form';
import { Button } from '../../../../shadcn/components/ui/button';
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../shadcn/components/ui/dialog';
import { FormFieldMediaUploader } from '../form/fields/FormFieldMediaUploader';

type SaleOptionCarouselImagesProps = {
  setIsDialogOpen: (value: boolean) => void;
};

export const SaleOptionCarouselImages = ({
  setIsDialogOpen,
}: SaleOptionCarouselImagesProps) => {
  const { fields, append, remove } = useFieldArray({ name: 'homepageImages' });

  return (
    <>
      <DialogHeader>
        <DialogTitle>Images suivante du carousel</DialogTitle>
        <DialogDescription>
          Sélectionnez les images à uploader pour le carousel.
        </DialogDescription>
      </DialogHeader>
      <div className="flex space-x-4 overflow-x-auto py-4">
        {fields.map((field, index) => (
          <div key={field.id} className="flex-shrink-0">
            <FormFieldMediaUploader
              fieldName={`homepageImages.${index}.url`}
              label="Homepage"
            />
          </div>
        ))}
      </div>
      <DialogFooter className="flex flex-col gap-y-2 sm:flex-col sm:space-x-0">
        <div className="flex flex-1 gap-x-2">
          <Button
            size="lg"
            className="flex flex-1 gap-x-3 border border-bluePrimary bg-beigePrimary font-bold text-bluePrimary hover:border-0 hover:bg-blueSecondary hover:text-white"
            onClick={() => append({ url: '' })}
          >
            <CirclePlus />
            <p className="mr-2">Ajouter</p>
          </Button>
          <Button
            variant="destructive_beige"
            size="lg"
            className="flex flex-1 gap-x-3 font-bold hover:bg-danger"
            onClick={() => remove(fields.length - 1)}
          >
            <CircleMinus />
            <p>Supprimer</p>
          </Button>
        </div>
        <div className="flex w-full flex-1">
          <Button
            type="button"
            size="lg"
            className="flex h-11 flex-1 gap-x-3 font-bold hover:bg-success"
            onClick={() => setIsDialogOpen(false)}
          >
            ENREGISTRER
          </Button>
        </div>
      </DialogFooter>
    </>
  );
};
