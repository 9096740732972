import { useRef, useEffect } from 'react';

export function useHorizontalScroll() {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    const onWheel = (event: WheelEvent) => {
      if (event.deltaY === 0) {
        return;
      }
      if (
        !(element.scrollLeft === 0 && event.deltaY < 0) &&
        !(
          element.scrollWidth -
            element.clientWidth -
            Math.round(element.scrollLeft) ===
            0 && event.deltaY > 0
        )
      ) {
        event.preventDefault();
      }

      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        event.preventDefault();
        element.scrollLeft += event.deltaX;
      }

      element.scrollTo({
        left: element.scrollLeft + event.deltaY,
        behavior: 'smooth',
      });
    };

    element.addEventListener('wheel', onWheel);

    return () => element.removeEventListener('wheel', onWheel);
  }, []);

  return elementRef;
}
