import axios from 'axios';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import { SaleCategory } from '../../types';

type SaleCategoryIdIndexDTO = Pick<SaleCategory, 'id' | 'index'>;

const reorderCategory = async (dto: SaleCategoryIdIndexDTO[]) => {
  await axios.post('/v1/category/ordering', dto);
  return dto;
};

export const reorderCategoryMutation = () => {
  return mutationOptions({
    mutationFn: reorderCategory,
    onSuccess: updateCache,
  });
};

// Cache invalidation after reordering
const updateCache = (dto: SaleCategoryIdIndexDTO[]) => {
  queryClient.setQueryData<SaleCategory[]>(
    [QueryKeys.CATEGORIES],
    (oldData) => {
      return oldData?.map((category) => {
        const updatedCategory = dto.find((item) => item.id === category.id);
        if (updatedCategory) {
          return {
            ...category,
            index: updatedCategory.index,
          };
        }
        return category;
      });
    }
  );
};
