import axios from 'axios';

const ROUTE_BASE = 'users';

export async function getMe(): Promise<User> {
  const response = await axios.get(`${ROUTE_BASE}/me`);

  return response.data.data;
}

export async function getUsers(): Promise<User[]> {
  const response = await axios.get(`${ROUTE_BASE}/list`);

  return response.data.data;
}

export async function deleteUser(user: User): Promise<{ success: boolean }> {
  const response = await axios.delete(`${ROUTE_BASE}/`, {
    data: { id: user.id },
  });

  return response.data.data;
}
