import { CirclePlus } from 'lucide-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../../../shadcn/components/ui/dialog';
import { SaleBlockType, SaleDataType } from '../../types';
import { FormFieldMediaUploader } from '../form/fields/FormFieldMediaUploader';
import { SaleOptionCarouselImages } from './SaleOptionCarouselImages';
import { UpcomingSaleFormSchema } from '../form/schema/UpcomingSaleFormSchema';
import { OngoingSaleFormSchema } from '../form/schema/OngoingSaleFormSchema';

type SaleOptionMediasUploaderProps = {
  blockType: SaleBlockType;
  isUpcomingSale?: boolean;
};

export const SaleOptionMediasUploader = ({
  isUpcomingSale,
  blockType,
}: SaleOptionMediasUploaderProps) => {
  const { watch } = useFormContext<
    OngoingSaleFormSchema | UpcomingSaleFormSchema
  >();
  const dataType = watch('dataType');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <MediaContainer>
      {blockType === SaleBlockType.CAROUSEL && (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <div className="absolute -left-5 -top-2 transform cursor-pointer rounded-full shadow shadow-blueSecondary transition-transform duration-300 hover:scale-125">
              <CirclePlus color="#5588cd" />
            </div>
          </DialogTrigger>
          <DialogContent>
            <SaleOptionCarouselImages setIsDialogOpen={setIsDialogOpen} />
          </DialogContent>
        </Dialog>
      )}
      <FormFieldMediaUploader
        fieldName={
          isUpcomingSale ? 'homepageImage.url' : 'homepageImages.0.url'
        }
        label="Homepage"
      />
      {dataType === SaleDataType.COLLECTION && (
        <FormFieldMediaUploader
          label="Collection"
          fieldName="collection.collectionImage"
        />
      )}
    </MediaContainer>
  );
};

const MediaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`;
