import React, { useState } from 'react';
import styled from 'styled-components';
import DownloadBlocIcon from '../../../../assets/icons/download-icon.svg';
import usePreview from '../../../../contexts/PreviewContext';
import { uploadFile } from '../../../../services/api/api';
import colors from '../../../../styles/colors.styles';
import Loader from '../../../loaders/Loader';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';

interface CarouselSectionProps {
  selectedItem: number;
  selectedTab: number;
  setSelectedTab: any;
}

const CarouselSection: React.FC<CarouselSectionProps> = ({
  selectedItem,
  selectedTab,
  setSelectedTab,
}) => {
  const preview = usePreview();

  const [uploading, setUploading] = useState<boolean[]>(
    preview.getPreviewItems()[selectedItem].content.items.map(() => false)
  );

  const handleUploadImage = async (event: any, index: number) => {
    setUploading(() => {
      const _uploading = [];
      _uploading[index] = true;
      return _uploading;
    });
    const file = event.target.files[0];
    if (!file) return;
    const path = await uploadFile(file);

    if (file) {
      preview.uploadImage(selectedItem, index, path);
    }
    event.target.value = '';
    setUploading(() => {
      const _uploading = [];
      _uploading[index] = false;
      return _uploading;
    });
  };

  const handleClick = (index: number) => {
    setSelectedTab(index);
  };

  const handleClickRemoveItem = (index: number) => {
    preview.removeCarouselItem(selectedItem, index);
  };

  const handleAddButton = () => {
    preview.addCarouselItem(selectedItem);
  };

  return (
    <UploadMultipleContainer>
      {preview
        .getSelectedItemTabs(selectedItem)
        .map((element: any, index: number) => (
          <UploadContainer
            key={index}
            isSelected={selectedTab === index}
            isImageloaded={element?.image?.defaultUrl}
          >
            <CloseButtonStyled onClick={() => handleClickRemoveItem(index)} />
            <Image
              src={
                element?.image?.defaultUrl
                  ? element?.image?.defaultUrl
                  : DownloadBlocIcon
              }
              alt=""
              style={{
                width: element?.image?.defaultUrl ? '100%' : 36,
                height: element?.image?.defaultUrl ? '100%' : 36,
              }}
            />
            {preview.getSelectedItemTabs(selectedItem)[index]?.image
              ?.defaultUrl ? (
              <Button onClick={() => handleClick(index)} />
            ) : (
              <Input
                type="file"
                name={`imageCarousel-${index}`}
                onChange={(event) => handleUploadImage(event, index)}
              />
            )}

            <Loader isVisible={uploading[index]} />
          </UploadContainer>
        ))}
      <AddButton onClick={handleAddButton} />
    </UploadMultipleContainer>
  );
};

export default CarouselSection;

const UploadMultipleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
`;

const UploadContainer = styled.label<{
  isSelected: boolean;
  isImageloaded: boolean;
}>`
  position: relative;
  width: 100px;
  height: 93px;
  margin: 10px 5px;
  border: ${(p: any) =>
    !p.isImageloaded
      ? `2px solid ${colors.blue.secondary}`
      : p.isSelected
        ? `2px solid ${colors.blue.primary}`
        : ''};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const Input = styled.input`
  display: none;
`;

const Button = styled.button`
  display: none;
`;

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`;

const Image = styled.img`
  display: flex;
  border-radius: 6px;
  object-fit: cover;
`;
