import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import TheBraderyLogoSVG from '../../../assets/icons/tb-logo.svg';
import { TabsList, TabsTrigger } from '../../../shadcn/components/ui/tabs';
import { categoriesQuery } from '../rest/get/getCategories';
import { useHorizontalScroll } from '../hooks/useHorizontalScroll';
import { cn } from '../../../shadcn/utils';
import { CATEGORY_ALL_SALLES, CATEGORY_UPCOMING_SALLES } from '../constants';

export const CategoriesList = () => {
  const { data: categories } = useQuery(categoriesQuery(false));
  const elementRef = useHorizontalScroll();

  const bottomBorderStyle = 'sales-category-border relative';

  return (
    <TabsList
      ref={elementRef}
      className="scrollbar-hide flex cursor-pointer justify-start overflow-scroll rounded-none bg-white p-0"
    >
      <TabsTrigger
        value={CATEGORY_ALL_SALLES}
        defaultFocusedStyle={false}
        className={cn(
          bottomBorderStyle,
          'data-[state=active]:font-bold data-[state=active]:text-bluePrimary'
        )}
      >
        <AllSalesCategoryContainer>
          <TheBraderyLogo
            src={TheBraderyLogoSVG}
            alt="The Bradery's logo"
            style={{
              width: 7,
              height: 14,
            }}
          />
          <p>Nos ventes</p>
        </AllSalesCategoryContainer>
      </TabsTrigger>
      <TabsTrigger
        value={CATEGORY_UPCOMING_SALLES}
        defaultFocusedStyle={false}
        className={cn(
          bottomBorderStyle,
          'data-[state=active]:font-bold data-[state=active]:text-bluePrimary'
        )}
      >
        À venir
      </TabsTrigger>
      {categories
        ?.sort((a, b) => a.index - b.index)
        ?.map((category) => (
          <TabsTrigger
            key={category.id}
            value={category.id}
            defaultFocusedStyle={false}
            className={cn(
              bottomBorderStyle,
              'data-[state=active]:font-bold data-[state=active]:text-bluePrimary'
            )}
          >
            {category.title}
          </TabsTrigger>
        ))}
    </TabsList>
  );
};

const AllSalesCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TheBraderyLogo = styled.img`
  height: 26px;
  width: auto;
`;
