import { DraggableUpcomingSale } from '../../pages/homepage/types';
import { isUpcomingSale } from './isUpcomingSale';

export const isDraggableUpcomingSale = (
  obj: any
): obj is DraggableUpcomingSale => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  if (
    !('id' in obj) ||
    (typeof obj.id !== 'string' && typeof obj.id !== 'number')
  ) {
    return false;
  }

  if (!('sale' in obj) || !isUpcomingSale(obj.sale)) {
    return false;
  }

  return true;
};
