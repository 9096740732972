import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import usePreview from '../../../../contexts/PreviewContext';
import DateInput from '../../inputs/DateInput.form';
import Title from '../miscellaneous/Title';

interface ExpectedDeliveryDateProps
  extends InputHTMLAttributes<HTMLInputElement> {
  selectedItem: number;
  selectedTab: number;
}

const ExpectedDeliveryDate: React.FC<ExpectedDeliveryDateProps> = ({
  selectedItem,
  selectedTab,
}) => {
  const preview = usePreview();

  const handleChange = (event: any) => {
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      'expectedDeliveryDate',
      event.target.value
    );
  };

  return (
    <Container>
      <Title>Date de livraison estimée</Title>
      <DateInput
        name={'expectedDeliveryDate'}
        date={
          preview.getSelectedItemTab(selectedItem, selectedTab)
            .expectedDeliveryDate
        }
        onChange={handleChange}
        hideTime
      />
    </Container>
  );
};

export default ExpectedDeliveryDate;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
