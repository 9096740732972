import axios from 'axios';

const ROUTE_ROOT = 'second-hand';

export async function getSecondHandConfig(): Promise<any> {
  const response = await axios.get(`${ROUTE_ROOT}`);
  return response.data.data;
}

export async function updateSecondHandConfig(jsonData: any): Promise<any> {
  const response = await axios.post(`${ROUTE_ROOT}`, {
    jsonData,
  });
  return response.data.data;
}
