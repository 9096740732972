import { queryOptions } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { CATEGORY_UPCOMING_SALLES } from '../../constants';
import {
  CategoryAllSales,
  CategoryUpcomingSales,
  OngoingSale,
  OngoingSaleDTO,
  SaleCategory,
} from '../../types';

type OngoingSaleBackendDTO = Omit<OngoingSaleDTO, 'id'> & { id: number };
type OngoingSalesBackendResponse = {
  ongoingSales: OngoingSaleBackendDTO[];
};

const getOngoingSales = async (
  categoryId: string,
  categories: SaleCategory[],
  signal?: AbortSignal
) => {
  // TODO change the response type
  const { data } = await axios.get<AxiosResponse<OngoingSalesBackendResponse>>(
    '/v1/ongoing-sales',
    {
      params: {
        categoryId,
      },
      signal,
    }
  );

  return data.data.ongoingSales.map((dto) => {
    const ongoingSale: OngoingSale = {
      ...dto,
      dateStart: dto.dateStart ? new Date(dto.dateStart) : undefined,
      dateStop: dto.dateStop ? new Date(dto.dateStop) : undefined,
      categories: categories.filter(({ id }) => dto.categories.includes(id)),
    };
    return ongoingSale;
  });
};

export const ongoingSalesQuery = (
  categoryId: string | CategoryAllSales | CategoryUpcomingSales,
  categories: SaleCategory[]
) => {
  return queryOptions({
    queryKey: [QueryKeys.ONGOING_SALES, categoryId],
    queryFn: ({ signal }) => getOngoingSales(categoryId, categories, signal),
    enabled: categoryId !== CATEGORY_UPCOMING_SALLES && categories.length > 0,
  });
};
