import { Button } from '../../../../shadcn/components/ui/button';
import { SheetFooter } from '../../../../shadcn/components/ui/sheet';
import { useHomePageContext } from '../../context/HomepageContext';

type SaleFormFooterProps = {
  isFromBackend: boolean;
  setIsDelete: (isDelete: boolean) => void;
};

export const SaleFormFooter = ({
  isFromBackend,
  setIsDelete,
}: SaleFormFooterProps) => {
  const context = useHomePageContext();

  return (
    <SheetFooter
      className={`flex flex-1 flex-col items-center ${isFromBackend ? 'gap-y-4' : ''}`}
    >
      {(context.isFormDirty || !isFromBackend) && (
        <Button type="submit" size="lg" className={`flex-1 hover:bg-success`}>
          ENREGISTRER
        </Button>
      )}
      {isFromBackend && (
        <Button
          variant="destructive_beige"
          className="flex-1"
          size="lg"
          onClick={() => setIsDelete(true)}
        >
          SUPPRIMER
        </Button>
      )}
    </SheetFooter>
  );
};
