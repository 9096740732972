import React from 'react';
import styled from 'styled-components';
import AddIcon from '../../../assets/icons/add.svg';

const AddButton: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (
  props
) => {
  return (
    <Container>
      <img src={AddIcon} alt="" {...props} />
    </Container>
  );
};

export default AddButton;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

  & > img {
    width: 17px;
    height: 17px;
  }
`;
