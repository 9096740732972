import React, { ButtonHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import { Loader } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  buttonType: 'primary' | 'secondary' | 'danger';
  isProcessing?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, buttonType, ...props }) => {
  const [pressStatus, setPressStatus] = useState<boolean>(false);

  const styleSelector = () => {
    if (buttonType === 'primary') {
      if (pressStatus) return styles.primaryPressed;
      return styles.primary;
    }
    if (buttonType === 'secondary') {
      if (pressStatus) return styles.secondaryPressed;
      return styles.secondary;
    }
    if (buttonType === 'danger') {
      if (pressStatus) return styles.dangerPressed;
      return styles.danger;
    }
    if (pressStatus) return styles.primaryPressed;
    return styles.primary;
  };

  const buildButtonStyle = () => {
    const style = {};

    Object.assign(style, styleSelector());
    Object.assign(style, props.style);

    return style;
  };

  return (
    <ButtonContainer
      onMouseDown={() => setPressStatus(true)}
      onMouseUp={() => setPressStatus(false)}
      onClick={props.onClick}
      style={buildButtonStyle()}
      {...props}
      disabled={props.isProcessing}
    >
      {props.isProcessing ? (
        <Loader inverse={buttonType === 'primary'} />
      ) : (
        text
      )}
    </ButtonContainer>
  );
};

export default Button;

const styles = {
  primary: {
    backgroundColor: colors.blue.primary,
    color: colors.mono.white,
    border: `${colors.blue.primary} solid 1px`,
    borderRadius: '7px',
  },
  secondary: {
    backgroundColor: 'none',
    border: `${colors.blue.primary} solid 1px`,
    borderRadius: '7px',
    color: colors.blue.primary,
  },
  tertiary: {
    backgroundColor: colors.blue.secondary,
    borderColor: colors.blue.secondary,
    color: colors.beige.primary,
  },
  danger: {
    backgroundColor: colors.mono.danger,
    color: colors.mono.white,
    border: `${colors.mono.danger} solid 1px`,
    borderRadius: '7px',
  },
  primaryPressed: {
    backgroundColor: colors.blue.quaternary,
    border: `${colors.blue.primary} solid 1px`,
    borderRadius: '7px',
  },
  secondaryPressed: {
    backgroundColor: 'none',
    border: `${colors.blue.primary} solid 1px`,
    borderRadius: '7px',
    color: colors.blue.primary,
  },
  tertiaryPressed: {
    backgroundColor: `${String(colors.blue.secondary)}99`,
    borderColor: `${String(colors.blue.secondary)}99`,
    color: colors.beige.primary,
  },
  dangerPressed: {
    backgroundColor: `${colors.mono.danger}88`,
    color: colors.mono.white,
    border: `${colors.mono.danger} solid 1px`,
    borderRadius: '7px',
  },
};

const ButtonContainer = styled.button`
  padding: 10px 15px;
  cursor: pointer;
  font-family: ${fonts.WorkSans.regular};
  display: flex;
  justify-content: center;
  align-items: center;
`;
