import { useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import colors from '../styles/colors.styles';
import fonts from '../styles/fonts.styles';
import HamburgerIcon from '../assets/icons/hamburger.svg';
import CloseIcon from '../assets/icons/close.svg';
import SVGButton from '../components/forms/buttons/SVGButton';
import SingleDroppable from '../components/droppable/SingleDroppable';
import Separator from '../components/miscellaneous/Separator';
import PreviewDroppable from '../components/draggableComponant/PreviewDroppable';
import AddButton from '../components/forms/buttons/AddButton';
import { v4 as uuidv4 } from 'uuid';
import {
  deleteCategory,
  getCategories,
  updateCategories,
} from '../services/api/category';
import usePreview from '../contexts/PreviewContext';
import { DroppableItem } from '../interfaces/droppable';

const grid = 2;

const Categories = () => {
  const preview = usePreview();

  const [categories, setCategories] = useState<DroppableItem[]>([]);

  const fetchCategories = async () => {
    const categoriesFetched = await getCategories();

    setCategories(
      categoriesFetched.map((el) => {
        return {
          id: (el.id ?? '').toString(),
          content: {
            title: el.title,
          },
        };
      })
    );
  };

  const handleUpdateCategories = (_categories: DroppableItem[]) => {
    const timeOutId = setTimeout(() => {
      const newCategories = _categories.map((el, index) => {
        return {
          ...el,
          content: {
            ...el.content,
            index: index,
          },
        };
      });
      updateCategories(newCategories);
    }, 1000);
    return () => clearTimeout(timeOutId);
  };

  useEffect(() => {
    fetchCategories();
    preview.loadHomeInProgressPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveCategory = async (index: number) => {
    setCategories(() =>
      categories.filter((el, i) => {
        if (i !== index) {
          return true;
        }
        deleteCategory(el.id);
        return false;
      })
    );
  };

  const handleAddFilter = () => {
    const newCategories = [
      ...categories,
      {
        id: uuidv4().toString(),
        content: {
          title: 'Nouveau filtre',
        },
      },
    ];
    setCategories(newCategories);
    handleUpdateCategories(newCategories);
  };

  const handleEditFilter = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCategories = categories.map((el, i) =>
      i === index
        ? {
            ...el,
            content: { ...el.content, title: event.target.value },
          }
        : el
    );
    setCategories(newCategories);
    handleUpdateCategories(newCategories);
  };

  const handleDragEnd = (categories: DroppableItem[]) => {
    handleUpdateCategories(categories);
  };

  return (
    <Container>
      <FiltersDroppableContainer>
        <Title>CATÉGORIES EXISTANTES</Title>
        <SingleDroppable
          items={categories}
          setItems={setCategories}
          listStyle={getListStyle}
          onDragEnd={handleDragEnd}
        >
          {categories.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided: any, snapshot: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    grid
                  )}
                >
                  <Category>
                    <img src={HamburgerIcon} alt="" width={14} height={14} />
                    <CategoryInputContainer>
                      <CategoryInput
                        length={item.content.title.length}
                        value={item.content.title}
                        onChange={(event) => handleEditFilter(index, event)}
                      />
                    </CategoryInputContainer>
                    <SVGButton
                      icon={CloseIcon}
                      width={30}
                      height={30}
                      onClick={() => handleRemoveCategory(index)}
                      style={{ cursor: 'pointer' }}
                    />
                  </Category>
                </div>
              )}
            </Draggable>
          ))}
        </SingleDroppable>
        <AddButton onClick={handleAddFilter} />
      </FiltersDroppableContainer>

      <Separator />
      <DragDropContext onDragEnd={() => {}}>
        <PreviewDroppable
          screenName="onGoingSales"
          grid={grid}
          isDragDisabled
          filters={categories}
        />
      </DragDropContext>
    </Container>
  );
};

export default Categories;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: '0 38px 0 38px';
  width: 350;
`;

const FiltersDroppableContainer = styled.div`
  padding: 35px 0px 0px 0px;
  margin: 0 40px 0 40px;
`;

const getListStyle = (isDraggingOver: boolean, grid: number) => ({
  background: isDraggingOver ? colors.beige.primary : colors.beige.primary,
  width: 350,
  flexGrow: 1,
});

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: CSSStyleRule,
  grid: number
) => ({
  userSelect: 'none',
  padding: 10,
  margin: `0 0 4px 0`,
  background: isDragging ? 'lightgreen' : '#fffcf9',
  color: colors.mono.white,
  fontFamily: fonts.WorkSans.regular,
  fontSize: '13px',
  height: '45px',
  display: 'flex',
  alignItems: 'center',
  ...draggableStyle,
});

const Category = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
`;

const CategoryInputContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 0 0 16px;
`;

const CategoryInput = styled.input<{ length: number }>`
  font-family: ${fonts.WorkSans.regular};
  font-size: 13px;
  color: ${colors.blue.primary};
  width: ${(p) => p.length + 3 + 'ch'};
  border: none;
  background-color: transparent;
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.secondary};
  margin: 0px 0 30px 0;
  text-align: center;
`;
