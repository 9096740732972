import { useLazyQuery } from '@apollo/client';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { CollectionData, getCollectionById } from '../../../../graphql/queries';
import Title from '../miscellaneous/Title';
import usePreview from '../../../../contexts/PreviewContext';
import Input from '../../inputs/Input';
import colors from '../../../../styles/colors.styles';
import { extractIdFromGid } from '../../../../helpers/shopify';

interface CollectionProps extends InputHTMLAttributes<HTMLInputElement> {
  selectedItem: number;
  selectedTab: number;
}

const Collection: React.FC<CollectionProps> = ({
  selectedItem,
  selectedTab,
}) => {
  const preview = usePreview();
  const [error, setError] = React.useState<string | null>(null);

  const [getCollection] = useLazyQuery<CollectionData>(getCollectionById, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const collection = data.collection;
      if (!collection) {
        setError("Cette collection n'existe pas.");
      }

      preview.setPreviewItemTabField(
        selectedItem,
        selectedTab,
        'collection',
        collection ? collection.title : ''
      );
    },
  });

  const handleChange = (event: any) => {
    setError(null);

    const collectionId = `gid://shopify/Collection/${event.target.value}`;
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      'id_shopify',
      event.target.value ? btoa(collectionId) : undefined
    );

    getCollection({
      variables: {
        id: collectionId,
      },
    });
  };

  return (
    <Container>
      <Title>Identifiant collection</Title>
      <Input
        type="number"
        name="collectionId"
        value={
          preview.getSelectedItemTab(selectedItem, selectedTab).id_shopify
            ? (extractIdFromGid(
                atob(
                  preview.getSelectedItemTab(selectedItem, selectedTab)
                    .id_shopify ?? ''
                )
              ) ?? undefined)
            : undefined
        }
        onChange={(event) => handleChange(event)}
      />
      {error === null ? (
        <CollectionTitle>
          {preview.getSelectedItemTab(selectedItem, selectedTab).collection}
        </CollectionTitle>
      ) : (
        <Error>{error}</Error>
      )}
    </Container>
  );
};

export default Collection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

const CollectionTitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  align-self: flex-start;
  font-style: italic;
`;

const Error = styled.small`
  color: ${colors.mono.danger};
  margin-top: 8px;
  font-size: 12px;
`;
