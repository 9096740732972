import styled from 'styled-components';
import fonts from '../../../../styles/fonts.styles';
import colors from '../../../../styles/colors.styles';
import { FormFieldInput } from '../form/fields/FormFieldInput';

export const UpcomingSaleOptionProductVendor = () => {
  return (
    <Container>
      <OptionTitle>Vendeur</OptionTitle>
      <FormFieldInput
        type="text"
        fieldName="productVendor"
        classNames={{
          container: 'w-full flex flex-col items-center space-y-0 gap-y-2',
          label:
            'font-workSans text-bluePrimary text-sm w-1/6 justify-self-end',
          input: 'w-2/3 bg-beigeSecondary',
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const OptionTitle = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.primary};
  align-self: center;
`;
