import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  CATEGORY_ALL_SALLES,
  CATEGORY_UPCOMING_SALLES,
  DND_SALE_CREATOR_ID,
} from '../../constants';
import { DraggableCreationBlock } from '../../types';
import SaleCreatorBlock from '../SaleCreatorBlock';
import { useHomePageContext } from '../../context/HomepageContext';

export type DraggableSaleCreatorProps = {
  draggable: DraggableCreationBlock;
};

export const DraggableSaleCreator = ({
  draggable,
}: DraggableSaleCreatorProps) => {
  const context = useHomePageContext();
  const { attributes, listeners, setNodeRef, isDragging, transform } =
    useDraggable({
      id: draggable.id,
      data: {
        containerId: DND_SALE_CREATOR_ID,
      },
      disabled:
        context.selectedCategory !== CATEGORY_UPCOMING_SALLES &&
        context.selectedCategory !== CATEGORY_ALL_SALLES,
    });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        zIndex: isDragging ? 1 : 0,
        transform: CSS.Translate.toString(transform),
      }}
    >
      <SaleCreatorBlock
        isDragging={isDragging}
        title={draggable.creationBlock.title}
      />
    </div>
  );
};
