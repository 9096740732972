import { gql, useLazyQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useHomePageContext } from '../../../context/HomepageContext';
import { OngoingSale } from '../../../types';
import { FormFieldInput } from '../../form/fields/FormFieldInput';
import { OngoingSaleFormSchema } from '../../form/schema/OngoingSaleFormSchema';

type CollectionItem = {
  id: string;
  title: string;
};

type CollectionData = {
  collection: CollectionItem;
};

export const getCollectionById = gql`
  query ($id: ID!) {
    collection(id: $id) {
      id
      title
    }
  }
`;

type CollectionUniqueOptionsProps = {
  ongoingSale: OngoingSale;
};

export const CollectionUniqueOptions = ({
  ongoingSale,
}: CollectionUniqueOptionsProps) => {
  const context = useHomePageContext();
  const { setError, getFieldState, clearErrors } =
    useFormContext<OngoingSaleFormSchema>();
  const { error } = getFieldState('collection.shopifyId');

  const [getCollection] = useLazyQuery<CollectionData>(getCollectionById, {
    fetchPolicy: 'network-only',
    onError: () => {
      if (error) {
        return;
      }
      setError('collection.shopifyId', {
        message: "Cette collection n'existe pas.",
      });
    },
    onCompleted: (data) => {
      const collection = data.collection;

      if (!collection) {
        if (!error) {
          setError('collection.shopifyId', {
            message: "Cette collection n'existe pas.",
          });
        }
        return;
      }

      if (error) {
        clearErrors('collection.shopifyId');
      }
      context.setSelectedOngoingSaleCollectionName(collection.title);
    },
  });

  const collectionName = ongoingSale.collection?.name;
  return (
    <Container>
      <FormFieldInput
        fieldName="collection.shopifyId"
        type="text"
        label="Identifiant shopify de la collection"
        classNames={{
          container: 'flex flex-col items-center w-full',
          label: 'font-workSansSemiBold text-bluePrimary',
          input:
            'font-workSansSemiBold border-greyPrimary  rounded-md bg-beigePrimary px-2 py-1 text-blueSecondary focus-visible:outline-blueSecondary focus-visible:ring-1',
        }}
        onChange={(field, value) => {
          if (value === '') {
            field.onChange(undefined);
            return;
          }

          getCollection({
            variables: { id: `gid://shopify/Collection/${value}` },
          });
          field.onChange(value);
        }}
        cancelNativeChange
      />
      {collectionName && (
        <CollectionTitle>
          Nom de la collection :{' '}
          <span className="font-bold">{collectionName}</span>
        </CollectionTitle>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`;

const CollectionTitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  align-self: flex-start;
  font-style: italic;
`;
