import { UpcomingSale } from '../../pages/homepage/types';
import { isUpcomingSaleAdditionnalImages } from './isUpcomingSaleAdditionalImages';

export const isUpcomingSale = (obj: any): obj is UpcomingSale => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  if (!('id' in obj) || typeof obj.id !== 'number') {
    return false;
  }

  if (!('index' in obj) || typeof obj.index !== 'number') {
    return false;
  }

  if ('dateStart' in obj && !(obj.dateStart instanceof Date)) {
    return false;
  }

  if ('dateStop' in obj && !(obj.dateStop instanceof Date)) {
    return false;
  }

  if (
    'additionalImages' in obj &&
    !isUpcomingSaleAdditionnalImages(obj.additionalImages)
  ) {
    return false;
  }

  if ('homepageImage' in obj && typeof obj.homepageImage !== 'object') {
    return false;
  }

  return true;
};
