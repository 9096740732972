import { DropResult } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { DroppableItem, DroppablePreviewBlocs } from '../interfaces/droppable';

export const updateIndexes = (
  source: DroppableItem[] | DroppablePreviewBlocs[]
) => {
  source.forEach((element, index) => {
    element.content.index = index;
  });
};

export const reorder = (
  list: DroppableItem[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const move = (
  source: DroppableItem[],
  destination: DroppablePreviewBlocs[],
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = [...source];
  const destClone: DroppableItem[] | DroppablePreviewBlocs[] = [...destination];

  if (droppableSource.droppableId === 'droppable1') {
    const item: DroppableItem = sourceClone[droppableSource.index];
    const itemClone = { ...item };

    destClone.splice(droppableDestination.index, 0, itemClone);

    destClone[droppableDestination.index] = {
      id: uuidv4(),
      content: {
        ...destClone[droppableDestination.index].content,
        index: droppableDestination.index,
      },
    };
  } else if (droppableSource.droppableId === 'droppable2') {
    sourceClone.splice(droppableSource.index, 1);
  }

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

interface OnDragEndResult {
  dropable1: any;
  dropable2: any;
}

export const onDragEnd = (
  result: DropResult,
  dropable1: DroppableItem[],
  dropable2: DroppablePreviewBlocs[]
): OnDragEndResult => {
  const { source, destination } = result;

  if (!destination) {
    return {
      dropable1,
      dropable2,
    };
  }

  if (source.droppableId === destination.droppableId) {
    let reOrdered = null;
    if (source.droppableId === 'droppable1') {
      reOrdered = reorder(dropable1, source.index, destination.index);
    } else {
      reOrdered = reorder(dropable2, source.index, destination.index);
    }

    if (source.droppableId === 'droppable2') {
      updateIndexes(reOrdered);
      return {
        dropable1,
        dropable2: reOrdered,
      };
    }
  } else {
    if (source.droppableId === 'droppable2') {
      const result = move(dropable2, dropable1, source, destination);
      updateIndexes(result.droppable2);
      return {
        dropable1,
        dropable2: result.droppable2,
      };
    } else if (source.droppableId === 'droppable1') {
      const result = move(dropable1, dropable2, source, destination);
      updateIndexes(result.droppable2);
      updateIndexes(result.droppable1);
      const json = JSON.parse(JSON.stringify(result.droppable2));
      return {
        dropable1: result.droppable1,
        dropable2: json,
      };
    }
  }
  return {
    dropable1,
    dropable2,
  };
};

export const dragItemInSingleDroppable = (
  result: DropResult,
  dropable: DroppableItem[]
) => {
  const { source, destination } = result;

  if (!destination) {
    return;
  }

  return reorder(dropable, source.index, destination.index);
};

export const grid = 3;

// Construct bloc to add object
export const constructorBlocDataOnGoingSale = () => {
  const bloc1 = {
    id: 'Bloc Image/Gif/Video',
    content: {
      blockType: 'normal',
      id: uuidv4(),
      index: undefined,
      items: [
        {
          datatype: 'collection',
          collection: '',
          category: 'une category',
          expressDelivery: false,
          dateStart: Date(),
          dateStop: Date(),
          expectedDelivery: '',
          discount: '55',
          image: {
            defaultUrl: undefined,
          },
          story: [],
          collectionImage: {},
        },
      ],
    },
  };

  const bloc3 = {
    id: 'Bloc Carousel',
    content: {
      blockType: 'carousel',
      id: uuidv4(),
      index: undefined,
      items: [
        {
          datatype: 'product',
          collection: '',
          category: 'une category',
          expressDelivery: false,
          dateStart: Date(),
          dateStop: Date(),
          expectedDelivery: '',
          discount: '',
          image: {
            defaultUrl: undefined,
          },
          collectionImage: {},
          story: [],
        },
      ],
    },
  };

  const data: DroppableItem[] = [bloc1, bloc3];

  return data;
};

export const constructorBlocDataUpComingSale = () => {
  const bloc1 = {
    id: 'Bloc Image/Gif/Video',
    content: {
      blockType: 'upComingSale',
      id: uuidv4(),
      index: undefined,
      items: [
        {
          datatype: 'upComingSale',
          dateStart: new Date(),
          dateStop: new Date(),
          text: '',
          image: {
            defaultUrl: undefined,
          },
          additionalImages: {},
        },
      ],
    },
  };

  const data: DroppableItem[] = [bloc1];

  return data;
};
