import { Image } from '../../interfaces/shop';

export function isImage(image: any): image is Image {
  return (
    image &&
    typeof image === 'object' &&
    (typeof image.defaultUrl === 'string' ||
      typeof image.verticalUrl === 'string')
  );
}
