import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';
import ClosedSection from '../../../assets/icons/closedSection.svg';
import OpenedSection from '../../../assets/icons/openedSection.svg';
import { Filler } from './sections/OptionsFiller';

interface OptionsAccordionProps {
  children: ReactNode;
  title: string;
  defaultState?: boolean;
}

const OptionsAccordion: React.FC<OptionsAccordionProps> = ({
  children,
  title,
  defaultState,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultState ?? false);

  return (
    <div>
      <TitleContainer>
        <Title onClick={() => setIsOpen(!isOpen)}>
          {title}
          <Image src={isOpen ? OpenedSection : ClosedSection} alt="" />
        </Title>
        <Filler />
      </TitleContainer>

      {isOpen && children}
    </div>
  );
};

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
  width: calc(100% + 32px);
  margin-left: -16px;

  font-size: 13px;
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.secondary};

  &:hover {
    cursor: pointer;
  }

  background-color: ${colors.beige.primary};
`;
const TitleContainer = styled.div`
  position: sticky;
  top: 0px;
  background-color: ${colors.beige.primary};
  z-index: 25;
`;

const Image = styled.img`
  height: 7px;
  width: 17px;
  margin-left: 7px;
`;

export default OptionsAccordion;
