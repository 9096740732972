import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '../../../../shadcn/components/ui/sheet';
import { SaleBlockType } from '../../types';
import { SaleOptionAdvanced } from '../sale_options/advanced_options/SaleOptionAdvanced';

type SaleFormHeaderProps = {
  blockType?: SaleBlockType;
};

export const SaleFormHeader = ({ blockType }: SaleFormHeaderProps) => {
  return (
    <SheetHeader className="border-beigeTertiary">
      <div className="flex items-center justify-between">
        <SheetTitle>
          Paramétrez une vente {blockType ? `- ${blockType}` : ''}
        </SheetTitle>
        <SaleOptionAdvanced />
      </div>
      <SheetDescription>
        Une fois la vente paramétrée, enregistrez les changements avec les
        boutons en bas de la page
      </SheetDescription>
    </SheetHeader>
  );
};
