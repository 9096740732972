import {
  OngoingSale,
  SaleBlockType,
  SaleDataType,
} from '../../pages/homepage/types';

export const isOngoingSale = (obj: any): obj is OngoingSale => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  if (!('id' in obj) || typeof obj.id !== 'number') {
    return false;
  }
  if (!('index' in obj) || typeof obj.index !== 'number') {
    return false;
  }
  if (
    !('isDisplayedInAllSales' in obj) ||
    typeof obj.isDisplayedInAllSales !== 'boolean'
  ) {
    return false;
  }
  if (
    !('isOnlyDisplayedToDevs' in obj) ||
    typeof obj.isOnlyDisplayedToDevs !== 'boolean'
  ) {
    return false;
  }
  if ('dateStart' in obj && !(obj.dateStart instanceof Date)) {
    return false;
  }
  if ('dateStop' in obj && !(obj.dateStop instanceof Date)) {
    return false;
  }
  if (
    !('dataType' in obj) ||
    typeof obj.dataType !== 'string' ||
    !Object.values(SaleDataType).includes(obj.dataType)
  ) {
    return false;
  }

  switch (obj.dataType) {
    case SaleDataType.COLLECTION:
      if (typeof obj.collection !== 'object' || obj.collection === null) {
        return false;
      }
      if (
        'discountAllAt' in obj &&
        typeof obj.discountAllAt !== 'number' &&
        'discountUpTo' in obj &&
        typeof obj.discountUpTo !== 'number'
      ) {
        return false;
      }
      break;
    case SaleDataType.PRODUCT:
      if (typeof obj.product !== 'object' || obj.product === null) {
        return false;
      }
      if (
        'discountAllAt' in obj &&
        typeof obj.discountAllAt !== 'number' &&
        'discountUpTo' in obj &&
        typeof obj.discountUpTo !== 'number'
      ) {
        return false;
      }
      break;
    case SaleDataType.WEBPAGE:
      if (typeof obj.webpage !== 'object' || obj.webpage === null) {
        return false;
      }
      break;
    case SaleDataType.STATIC:
      return true;
    default:
      return false;
  }

  if (
    !('blockType' in obj) ||
    typeof obj.blockType !== 'string' ||
    !Object.values(SaleBlockType).includes(obj.blockType)
  ) {
    return false;
  }
  if (!('categories' in obj) || !Array.isArray(obj.categories)) {
    return false;
  }
  if (!('homepageImages' in obj) || !Array.isArray(obj.homepageImages)) {
    return false;
  }

  return true;
};
