import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import CloseIcon from '../../../assets/icons/closeFilled.svg';

const CloseButton: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Container {...props}>
      <Image src={CloseIcon} alt="" />
    </Container>
  );
};

export default CloseButton;

const Container = styled.div`
  display: block;
  cursor: pointer;
`;

const Image = styled.img`
  flex: 1;
`;
