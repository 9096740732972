import React from 'react';
import styled from 'styled-components';
import LoaderIcon from '../../assets/icons/loader.svg';

interface LoaderProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  isVisible: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isVisible, ...props }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <LoaderContainer>
      <img src={LoaderIcon} alt="" {...props} />
    </LoaderContainer>
  );
};

export default Loader;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 40px;
    height: 40px;
  }
`;
