import { queryOptions } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { CATEGORY_UPCOMING_SALLES } from '../../constants';
import {
  CategoryAllSales,
  CategoryUpcomingSales,
  UpcomingSale,
  UpcomingSaleDTO,
} from '../../types';

type UpcomingSaleBackendDTO = Omit<UpcomingSaleDTO, 'id'> & { id: number };
type UpcomingSalesBackendResponse = {
  upcomingSales: UpcomingSaleBackendDTO[];
};

const getUpcomingSales = async (signal?: AbortSignal) => {
  const { data } = await axios.get<AxiosResponse<UpcomingSalesBackendResponse>>(
    '/v1/upcoming-sales',
    {
      signal,
    }
  );

  return data.data.upcomingSales.map((dto) => {
    const sale: UpcomingSale = {
      ...dto,
      dateStart: dto.dateStart ? new Date(dto.dateStart) : undefined,
      dateStop: dto.dateStop ? new Date(dto.dateStop) : undefined,
    };
    return sale;
  });
};

export const upcomingSalesQuery = (
  categoryId: string | CategoryAllSales | CategoryUpcomingSales
) => {
  return queryOptions({
    queryKey: [QueryKeys.UPCOMING_SALES],
    queryFn: ({ signal }) => getUpcomingSales(signal),
    enabled: categoryId === CATEGORY_UPCOMING_SALLES,
  });
};
