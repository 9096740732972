import React from 'react';

interface ImageOrVideoProps {
  url: string;
  style: React.CSSProperties;
}

const ImageOrVideo: React.FC<ImageOrVideoProps> = ({ url, style }) => {
  const getUrlExtension = (url: string) => {
    return url.split(/[#?]/)[0].split('.').pop()?.trim();
  };

  const fileType = (pathUrl: string) => {
    const imageTypes = ['jpg', 'png', 'gif', 'svg', 'jpeg', 'webp'];
    const videoTypes = ['mp4', 'mov'];

    if (!pathUrl) {
      return undefined;
    }
    const extension = getUrlExtension(pathUrl);
    if (!extension) {
      return undefined;
    }

    if (videoTypes.includes(extension)) {
      return `video`;
    }
    if (imageTypes.includes(extension)) {
      return `image`;
    }
    return undefined;
  };

  if (fileType(url) === 'video') {
    return (
      <video loop autoPlay style={style}>
        <source src={url} type="video/mp4" />
      </video>
    );
  }

  if (fileType(url) === 'image') {
    return <img src={url} alt="" width="100px" height="100px" style={style} />;
  }
  return null;
};

export default ImageOrVideo;
