import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getMe } from '../services/users.service';
import { loginUser } from '../services/auth.service';
import { AxiosError } from 'axios';
import TokenService from '../services/tokens';

interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: AxiosError;
  jwt?: string;
  login: (
    loginData: LoginDataType,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  logout: () => void;
}

export interface LoginDataType {
  email: string;
  password: string;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [jwt, setJWT] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  // const location = useLocation();

  // If we change page, reset the error state.
  // useEffect(() => {
  // 	if (error) setError(null);
  // }, [location.pathname]);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    const storedJWT = TokenService.getJWT();

    if (!!storedJWT) {
      getMe()
        .then((user) => {
          setUser(user);
          setJWT(storedJWT);
        })
        .catch((_error) => {})
        .finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
  }, []);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  function login(
    loginData: LoginDataType,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    setLoading(true);
    setError(null);

    loginUser(loginData)
      .then((signInUserResponse) => {
        TokenService.setJWT(signInUserResponse.jwt);
        setUser(signInUserResponse.user);
        setJWT(signInUserResponse.jwt);

        setSubmitting(false);
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 401) {
          setError(err);
        }

        setSubmitting(false);
      })
      .finally(() => setLoading(false));
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout() {
    setUser(undefined);
    setJWT(undefined);
    TokenService.removeJWT();
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
      jwt,
    }),
    [user, loading, error, jwt]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
