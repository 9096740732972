export interface Fonts {
  Archivo: {
    regular: string;
    bold: string;
    light: string;
    medium: string;
  };
  Helvetica: {
    regular: string;
    bold: string;
    light: string;
  };
  WorkSans: {
    regular: string;
    semiBold: string;
    bold: string;
    light: string;
    medium: string;
  };
}

const fonts: Fonts = {
  Archivo: {
    regular: 'Archivo-Regular',
    bold: 'Archivo-Bold',
    light: 'Archivo-Light',
    medium: 'Archivo-Medium',
  },
  Helvetica: {
    regular: 'Helvetica',
    bold: 'Helvetica-Bold',
    light: 'Helvetica-Light',
  },
  WorkSans: {
    regular: 'WorkSans-Regular',
    semiBold: 'WorkSans-SemiBold',
    bold: 'WorkSans-Bold',
    light: 'WorkSans-Light',
    medium: 'WorkSans-Medium',
  },
};

export default fonts;
