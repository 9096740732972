import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../../shadcn/components/ui/form';
import { Textarea } from '../../../../../shadcn/components/ui/textarea';

type FormFieldTextareaProps = {
  fieldName: string;
  label?: string;
  description?: string;
  value?: string;
  placeholder?: string;

  classNames?: {
    container?: string;
    textarea?: string;
    label?: string;
    description?: string;
  };

  onChange?: (
    field: ControllerRenderProps<FieldValues, string>,
    value: string
  ) => void;
  cancelNativeChange?: boolean;
};

export const FormFieldTextarea = ({
  fieldName,
  label,
  description,
  onChange,
  value,
  placeholder,
  classNames,
  cancelNativeChange = false,
}: FormFieldTextareaProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className={classNames?.container}>
          {label && (
            <FormLabel className={classNames?.label} htmlFor={fieldName}>
              {label}
            </FormLabel>
          )}
          <FormControl>
            <Textarea
              id={fieldName}
              value={value ?? field.value ?? ''}
              className={classNames?.textarea}
              placeholder={placeholder}
              onChange={(e) => {
                onChange?.(field, e.target.value);
                if (!cancelNativeChange) {
                  field.onChange(e.target.value);
                }
              }}
            />
          </FormControl>
          {description && (
            <FormDescription className={classNames?.description}>
              {description}
            </FormDescription>
          )}
          <FormMessage className="animate-shake" />
        </FormItem>
      )}
    />
  );
};
