import { gql, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useHomePageContext } from '../../../context/HomepageContext';
import { OngoingSale } from '../../../types';
import { FormFieldInput } from '../../form/fields/FormFieldInput';
import { OngoingSaleFormSchema } from '../../form/schema/OngoingSaleFormSchema';

type GQLProduct = {
  title: string;
};

type GQLProductData = {
  product: GQLProduct;
};

const getProduct = gql`
  query getProductById($id: ID!) {
    product(id: $id) {
      title
    }
  }
`;

type ProductUniqueOptionsProps = {
  ongoingSale: OngoingSale;
};

export const ProductUniqueOptions = ({
  ongoingSale,
}: ProductUniqueOptionsProps) => {
  const context = useHomePageContext();

  const { watch, setError, getFieldState, clearErrors } =
    useFormContext<OngoingSaleFormSchema>();
  const { error } = getFieldState('product.shopifyId');
  const productId = watch('product.shopifyId', undefined);

  const { refetch } = useQuery<GQLProductData>(getProduct, {
    fetchPolicy: 'cache-and-network',
    skip: productId === undefined,
    variables: { id: `gid://shopify/Product/${productId}` },
    onError: (error) => {
      if (error) {
        return;
      }
      setError('product.shopifyId', {
        message: "Ce produit n'existe pas",
      });
    },
    onCompleted: (data) => {
      if (!data.product) {
        if (!error) {
          setError('product.shopifyId', {
            message: "Ce produit n'existe pas",
          });
        }
        return;
      }

      if (error) {
        clearErrors('product.shopifyId');
      }

      context.setSelectedOngoingSaleProductName(data.product.title);
    },
  });

  const productName = ongoingSale.product?.name;
  return (
    <Container>
      <FormFieldInput
        fieldName="product.shopifyId"
        type="text"
        label="Identifiant shopify du produit"
        classNames={{
          container: 'flex flex-col items-center w-5/6',
          label: 'font-workSansSemiBold text-bluePrimary',
          input:
            'font-workSansSemiBold border-greyPrimary rounded-md bg-beigePrimary px-2 py-1 text-blueSecondary focus-visible:outline-blueSecondary focus-visible:ring-1',
        }}
        onChange={(field, value) => {
          if (value === '') {
            field.onChange(undefined);
            return;
          }

          refetch({ id: `gid://shopify/Product/${value}` });
          field.onChange(value);
        }}
        cancelNativeChange
      />
      {productName && (
        <ProductTitle>
          Nom du produit : <span className="font-bold">{productName}</span>
        </ProductTitle>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`;

const ProductTitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  align-self: flex-start;
  font-style: italic;
`;
