import { isOngoingSale } from '../../../../utils/typguards/isOngoingSale';
import { isUpcomingSale } from '../../../../utils/typguards/isUpcomingSale';
import { OngoingSale, UpcomingSale } from '../../types';
import { OngoingSaleForm } from './OngoingSaleForm';
import { UpcomingSaleForm } from './UpcomingSaleForm';

type SaleFormProps = {
  sale: OngoingSale | UpcomingSale;
};

export const SaleForm = ({ sale }: SaleFormProps) => {
  if (isOngoingSale(sale)) {
    return <OngoingSaleForm ongoingSale={sale} />;
  } else if (isUpcomingSale(sale)) {
    return <UpcomingSaleForm upcomingSale={sale} />;
  }

  return null;
};
