import {
  calculateDaysRemaining,
  calculateHoursRemaining,
  calculateMinutesRemaining,
} from '../../../helpers/time';

export const renderSaleTimeRemaining = (dateStop: Date) => {
  const daysRemaining = calculateDaysRemaining(dateStop);
  const hoursRemaining = calculateHoursRemaining(dateStop);
  const minutesRemaining = calculateMinutesRemaining(dateStop);

  if (minutesRemaining < 0) {
    return 'La vente est terminée';
  }
  if (daysRemaining > 1) {
    return `Se termine dans ${daysRemaining} jours`;
  }
  if (hoursRemaining > 1) {
    return `Se termine dans ${hoursRemaining} heures`;
  }

  return `Se termine dans ${minutesRemaining} minutes`;
};
