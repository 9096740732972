import { calculateDaysRemaining } from '../../../helpers/time';

export const renderTimeRemainingBeforeTheSaleBegins = (dateStart: Date) => {
  const daysRemaining = calculateDaysRemaining(dateStart);
  if (Object.is(daysRemaining, +0)) {
    return 'La vente débute bientôt';
  }
  if (daysRemaining === 1) {
    return 'La vente débute demain';
  }
  if (daysRemaining > 1) {
    return `La vente débute dans ${calculateDaysRemaining(dateStart)} jours`;
  }
  return `La vente a déjà commencé`;
};
