import { DraggableUpcomingSale } from '../../pages/homepage/types';
import { isDraggableUpcomingSale } from './isDraggableUpcomingSale';

export const isDraggableUpcomingSaleArray = (
  obj: any
): obj is DraggableUpcomingSale[] => {
  return (
    Array.isArray(obj) && obj.some((item) => isDraggableUpcomingSale(item))
  );
};
