import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors.styles';
import fonts from '../../../styles/fonts.styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input: React.FC<InputProps> = (props) => {
  return (
    <Container>
      {props.label && <LabelContainer>{props.label}</LabelContainer>}
      <InputContainer type="text" {...props} />
    </Container>
  );
};

export default Input;

const InputContainer = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-color: ${colors.beige.tertiary};
  border: none;
  border-radius: 4px;
  padding: 11px 11px 11px 11px;
  font-family: ${fonts.WorkSans.regular};
  font-size: 12px;
  color: ${colors.blue.primary};

  &:focus {
    outline: 1px solid ${colors.blue.secondary};
  }
`;

const LabelContainer = styled.div`
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  width: 100%;
`;
