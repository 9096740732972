import { z } from 'zod';

export type UpcomingSaleFormSchema = z.infer<typeof UPCOMING_SALE_FORM_SCHEMA>;

// Intersecting the schema to force the refine to run even when it isn't valid
// https://github.com/colinhacks/zod/issues/479
export const UPCOMING_SALE_FORM_SCHEMA = z.intersection(
  z
    .object({
      dateStart: z.date({ message: 'Date manquante' }),
      dateStop: z.date({ message: 'Date manquante' }),
    })
    .refine((data) => data.dateStart < data.dateStop, {
      message: 'La date de début doit être antérieure à la date de fin.',
      path: ['dates'],
    }),
  z.object({
    lockHomepagePosition: z.boolean(),
    homepageImage: z.object({
      url: z
        .string({ message: 'Image manquante' })
        .url('URL invalide.')
        .min(1, 'URL requis.'),
    }),
    productVendor: z.string({ message: 'Vendeur manquant' }),
    description: z.string({ message: 'Description manquante' }),
    additionalImages: z.object({
      leftUrl: z.string({ message: 'Image manquante' }).url('URL invalide.'),
      rightUrl: z.string({ message: 'Image manquante' }).url('URL invalide.'),
    }),
    isOnlyDisplayedToDevs: z.boolean(),
  })
);
