import { DraggableOngoingSale } from '../../pages/homepage/types';
import { isOngoingSale } from './isOngoingSale';

export const isDraggableOngoingSale = (
  obj: any
): obj is DraggableOngoingSale => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  if (
    !('id' in obj) &&
    (typeof obj.id !== 'string' || typeof obj.id !== 'number')
  ) {
    return false;
  }

  if (!isOngoingSale(obj.sale)) {
    return false;
  }
  return true;
};
