import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import AddBlocDroppable from '../components/draggableComponant/AddBlocDroppable';
import PreviewDroppable from '../components/draggableComponant/PreviewDroppable';
import BlocOptionsSection from '../components/forms/BlocOptionsSection';
import Separator from '../components/miscellaneous/Separator';
import usePreview from '../contexts/PreviewContext';
import { updateItem, updateOrderingOngoingSale } from '../services/api/api';
import { getCategories } from '../services/api/category';
import {
  constructorBlocDataOnGoingSale,
  grid,
  onDragEnd,
} from '../utils/droppable';
import { DroppableItem, DroppablePreviewBlocs } from '../interfaces/droppable';
import { isHomeItemWithIdAndIndex } from '../utils/typguards/isHomeItemWithIdAndIndex';
import { isUpComingSaleWithIdAndIndex } from '../utils/typguards/isUpComingSaleWithIdAndIndex';

const HomeInProgress: React.FC = () => {
  const [blocsItems, setBlocsItems] = useState<DroppableItem[]>(
    constructorBlocDataOnGoingSale
  );
  const [showModalOption, setShowModalOption] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [isButtonSubmitProcessing, setIsButtonSubmitProcessing] =
    useState<boolean>(false);
  const [isButtonRemoveBlocProcessing, setIsButtonRemoveBlocProcessing] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [categories, setCategories] = useState<DroppableItem[]>([]);

  const preview = usePreview();

  useEffect(() => {
    fetchCategories();
    preview.setPreviewType('inProgress');
    preview.loadHomeInProgressPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategories = async () => {
    const categoriesFetched = await getCategories();

    setCategories(
      categoriesFetched.map((el) => {
        return {
          id: (el.id ?? '').toString(),
          content: {
            title: el.title,
          },
        };
      })
    );
  };

  const handleOnDragEnd = (result: DropResult) => {
    const data = onDragEnd(result, blocsItems, preview.getPreviewItems());
    setBlocsItems(data.dropable1);
    preview.setPreviewItems()(data.dropable2);
    if (
      result.destination?.droppableId === 'droppable2' &&
      result.source?.droppableId === 'droppable2'
    ) {
      updateOrderingOngoingSale(
        data.dropable2.map((item: any) => {
          return {
            id: item.content.id,
            index: item.content.index,
          };
        })
      );
    } else if (
      result.destination?.droppableId === 'droppable1' &&
      result.source?.droppableId === 'droppable2'
    ) {
      handleRemoveBloc(
        preview.getPreviewItems().find((el) => el.id === result.draggableId)
      );
    }
  };

  const handleClickConfirmAddBlock = async (item: DroppablePreviewBlocs) => {
    setIsButtonSubmitProcessing(true);
    try {
      const itemId = (await updateItem('inProgress', item)).data.data.id;

      const updatedPreview = preview.updatePreviewItemById(item.id, {
        ...item,
        content: { ...item.content, id: itemId },
      });

      const validEntries = updatedPreview
        .filter(
          (x) =>
            isUpComingSaleWithIdAndIndex(x.content) ||
            isHomeItemWithIdAndIndex(x.content)
        )
        .map((x) => {
          // Check if 'id' is number, otherwise try to parse it if it's not undefined.
          const id =
            typeof x.content.id === 'number'
              ? x.content.id
              : typeof x.content.id === 'string'
                ? parseInt(x.content.id)
                : null; // or throw an error, or continue without adding this entry

          const index = x.content.index;

          // Handle the case when id or index might still be undefined
          if (id === null || index === undefined) {
            // Decide how to handle this case: skip, use default values, or throw an error
            throw new Error('Invalid or missing id or index');
          }

          return {
            id,
            index,
          };
        });

      await updateOrderingOngoingSale(validEntries);

      setShowModalOption(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    } finally {
      setIsButtonSubmitProcessing(false);
    }
  };

  const handleRemoveBloc = async (item: any) => {
    setIsButtonRemoveBlocProcessing(true);
    const newPreviewItems = preview
      .getPreviewItems()
      .filter((previewItem) => item.id !== previewItem.id);
    preview.setPreviewItems()(newPreviewItems);
    try {
      await axios.delete('/modular/ongoingSales/' + item.content.id);
      setShowModalOption(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    } finally {
      setIsButtonRemoveBlocProcessing(false);
    }
  };

  const handleModalOption = (index: number) => {
    setShowModalOption(true);
    setSelectedItem(index);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <AddBlocDroppable blocsItems={blocsItems} />
      <Separator />
      <PreviewDroppable
        screenName="onGoingSales"
        grid={grid}
        handleModalOption={handleModalOption}
        filters={categories}
      />
      {selectedItem !== undefined &&
        preview.getPreviewItems()[selectedItem] && (
          <BlocOptionsSection
            onClickConfirm={handleClickConfirmAddBlock}
            selectedItem={selectedItem}
            visible={showModalOption}
            onRemoveBloc={handleRemoveBloc}
            isButtonSubmitProcessing={isButtonSubmitProcessing}
            isButtonRemoveBlocProcessing={isButtonRemoveBlocProcessing}
            error={error}
          />
        )}
    </DragDropContext>
  );
};

export default HomeInProgress;
