import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAuth from '../contexts/AuthContext';
import colors from '../styles/colors.styles';
import ButtonNavbar from './forms/buttons/ButtonNavbar';
import SubButtonNavbar from './forms/buttons/SubButtonNavbar';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar: React.FC = () => {
  const { logout, user } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [dev, setDev] = useState<boolean>(false);

  useEffect(() => {
    setDev(localStorage.getItem('devMode') === 'true');
  }, [location.search]);

  const handleSwitch = () => {
    localStorage.setItem(
      'devMode',
      localStorage.getItem('devMode') !== 'true' ? 'true' : 'false'
    );
    navigate(0); // refresh
  };

  return (
    <NavContainer>
      <NavTopSectionContainer>
        {dev && <Info>MODE DEVELOPPEMENT ACTIF</Info>}
        <SectionTitle>Écrans</SectionTitle>
        <ButtonNavbar type="link" text="HOMEPAGE" to="/homepage" />
        <ButtonNavbar
          type="menu"
          text="ÉCRANS MODULABLES"
          subNavigationLocations={[
            '/second-hand',
            '/marketplace',
            '/christmas',
            '/travels',
          ]}
        >
          <SubButtonNavbar type="link" text="SECONDE MAIN" to="/second-hand" />
          <SubButtonNavbar type="link" text="MARKETPLACE" to="/marketplace" />
          <SubButtonNavbar type="link" text="CHRISTMAS" to="/christmas" />
          <SubButtonNavbar type="link" text="VOYAGE" to="/travels" />
        </ButtonNavbar>
        <SectionTitle>Autres</SectionTitle>
        <ButtonNavbar type="link" text="JEUX CONCOURS" to="/quiz" />
        <ButtonNavbar
          type="menu"
          text="LEGACY"
          subNavigationLocations={['/quiz_deprecated']}
        >
          <SubButtonNavbar
            type="link"
            text="JEUX CONCOURS (LEGACY)"
            to="/quiz_deprecated"
          />
          <SubButtonNavbar
            type="link"
            text="PAGE VENTE EN COURS (LEGACY)"
            to="/home-in-progress"
          />
          <SubButtonNavbar
            type="link"
            text="PAGE VENTE À VENIR (LEGACY)"
            to="/home-up-coming"
          />
          <SubButtonNavbar
            type="link"
            text="VENTES EN COURS/CATÉGORIES"
            to="/categories"
          />
        </ButtonNavbar>
      </NavTopSectionContainer>

      <NavBottomSectionContainer>
        <ButtonNavbar
          type="menu"
          text="RÉGLAGES"
          subNavigationLocations={['/users']}
        >
          {user && user.role === 'admin' && (
            <SubButtonNavbar type="link" text="UTILISATEURS" to="/users" />
          )}
          <SubButtonNavbar type="link" text="PARAMÈTRES" to="/settings" />
          <SubButtonNavbar
            type="action"
            text={dev ? 'PRODUCTION' : 'DEVELOPPEMENT'}
            onClick={handleSwitch}
          />
          <SubButtonNavbar type="action" text="DÉCONNEXION" onClick={logout} />
        </ButtonNavbar>
      </NavBottomSectionContainer>
    </NavContainer>
  );
};

export default Navbar;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  background-color: ${colors.beige.primary};
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
`;

const NavTopSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
`;

const NavBottomSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
`;

const Info = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 12px;
`;

const SectionTitle = styled.h2`
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  border-bottom: 1px solid ${colors.grey.primary};
`;
