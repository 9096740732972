import React from 'react';
import styled from 'styled-components';
import usePreview from '../../../../contexts/PreviewContext';
import Input from '../../inputs/Input';
import Title from '../miscellaneous/Title';
import Checkbox from '../../inputs/Checkbox.form';

interface WebpageProps {
  selectedItem: number;
  selectedTab: number;
}

const Webpage: React.FC<WebpageProps> = ({ selectedItem, selectedTab }) => {
  const preview = usePreview();

  const handleChange = (event: any) => {
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      'webpageUrl',
      event.target.value
    );
  };

  const handleOpenInappChange = (event: any) => {
    preview.setPreviewItemTabField(
      selectedItem,
      selectedTab,
      'openInApp',
      event.target.checked
    );
  };

  return (
    <Container>
      <Title>Écrire l’URL</Title>
      <Input
        type="text"
        name="webpageUrl"
        value={
          preview.getSelectedItemTab(selectedItem, selectedTab).webpageUrl || ''
        }
        onChange={handleChange}
      />

      <Title>Comportememnt</Title>
      <Checkbox
        label="Ouvrir dans l'application"
        checked={
          preview.getSelectedItemTab(selectedItem, selectedTab).openInApp ||
          false
        }
        onChange={handleOpenInappChange}
      />
    </Container>
  );
};

export default Webpage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`;
