export const calculateDaysRemaining = (date: Date | undefined): number => {
  if (!date) {
    return 0;
  }

  const ms = date.getTime() - new Date().getTime();
  const days = ms / (1000 * 60 * 60 * 24);

  return Math.round(days);
};

export const calculateHoursRemaining = (date: Date | undefined): number => {
  if (!date) {
    return 0;
  }

  const ms = date.getTime() - new Date().getTime();
  const hours = ms / (1000 * 60 * 60);
  return Math.round(hours);
};

export const calculateMinutesRemaining = (date: Date | undefined): number => {
  if (!date) {
    return 0;
  }

  const ms = date.getTime() - new Date().getTime();
  const hours = ms / (1000 * 60);
  return Math.round(hours);
};
