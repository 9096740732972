import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '../../../../../shadcn/components/ui/form';
import { Switch } from '../../../../../shadcn/components/ui/switch';
import { cn } from '../../../../../shadcn/utils';

type FormFieldSwitchProps = {
  fieldName: string;
  label?: string;
  description?: string;
  value?: boolean;

  // If tailwind autocompletes isn't working,
  // have a look: https://stackoverflow.com/a/73655079/10885193
  classNames?: {
    container?: string;
    switch?: string;
    label?: string;
    description?: string;
  };

  onChange?: (value: boolean) => void;
};

export const FormFieldSwitch = ({
  fieldName,
  label,
  description,
  value,
  onChange,
  classNames,
}: FormFieldSwitchProps) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem
          className={cn(
            'flex items-center justify-around space-y-0',
            classNames?.container
          )}
        >
          <div className="space-y-0.5">
            {label && (
              <FormLabel htmlFor={fieldName} className={classNames?.label}>
                {label}
              </FormLabel>
            )}
            {description && (
              <FormDescription className={classNames?.description}>
                {description}
              </FormDescription>
            )}
          </div>
          <FormControl>
            <Switch
              id={fieldName}
              checked={field.value ?? value ?? false}
              className={classNames?.switch}
              onCheckedChange={(checked) => {
                field.onChange(checked);
                onChange?.(checked);
              }}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );
};
