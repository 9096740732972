import React from 'react';

interface SVGButtonProps {
  icon: string;
  width: number;
  height: number;
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
}

const SVGButton: React.FC<SVGButtonProps> = ({
  icon,
  width,
  height,
  style = {},
  onClick,
  ...props
}) => {
  return (
    <img
      src={icon}
      alt=""
      style={{ ...{ width, height }, ...style }}
      onClick={onClick}
    />
  );
};

export default SVGButton;
