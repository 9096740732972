import { useQuery } from '@tanstack/react-query';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../shadcn/components/tooltip';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../shadcn/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
} from '../../../../shadcn/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../../shadcn/components/ui/popover';
import { cn } from '../../../../shadcn/utils';
import { categoriesQuery } from '../../rest/get/getCategories';
import { SaleCategory } from '../../types';
import { FormFieldSwitch } from '../form/fields/FormFieldSwitch';
import { OngoingSaleFormSchema } from '../form/schema/OngoingSaleFormSchema';
import colors from './../../../../styles/colors.styles';

export const SaleOptionCategories = () => {
  const [open, setOpen] = useState(false);

  const { data: categories } = useQuery(categoriesQuery(false));

  const { control } = useFormContext<OngoingSaleFormSchema>();
  const { append, remove } = useFieldArray<OngoingSaleFormSchema>({
    name: 'categories',
  });
  const selectedCategories = useWatch({ control, name: 'categories' })
    .map((c) => {
      const category = categories?.find((category) => category.id === c.id);
      return category ? { id: category.id, title: category.title } : undefined;
    })
    .filter((c) => c !== undefined);

  const onCategorySelect = (category: SaleCategory) => {
    const isAlreadySelected = selectedCategories.find(
      (c) => c.id === category.id
    );
    if (isAlreadySelected) {
      remove(selectedCategories.findIndex((c) => c.id === category.id));
    } else {
      append(category);
    }
  };

  return (
    <Container>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <div className="w-full">
              <FormFieldSwitch
                fieldName="isDisplayedInAllSales"
                label="Sélectionner les catégories"
                classNames={{
                  label: 'font-workSansSemiBold text-bluePrimary',
                }}
              />
            </div>
          </TooltipTrigger>
          <TooltipContent align="end" alignOffset={-20}>
            <p>
              Afficher dans <strong>Nos ventes</strong>
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <FormField
        control={control}
        name="categories"
        render={() => (
          <FormItem className="w-full">
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <FormControl className="w-full">
                  <span
                    role="combobox"
                    aria-controls="categories"
                    aria-expanded={open}
                    className="flex w-full cursor-pointer items-center justify-between rounded-md border-2 p-2"
                  >
                    <CategoriesContainer>
                      {selectedCategories.length > 0
                        ? selectedCategories.map((category, index) => (
                            <CategoryName
                              key={category.id}
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                            >
                              {category.title}
                            </CategoryName>
                          ))
                        : 'Sélectionnez les catégories...'}
                    </CategoriesContainer>

                    <ChevronsUpDown className="h-6 w-6 shrink-0 opacity-50" />
                  </span>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent>
                <Command>
                  <CommandInput placeholder="Rechercher..." />
                  <CommandList>
                    <CommandEmpty>Aucune catégorie.</CommandEmpty>
                    <CommandGroup>
                      {categories
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .map((category) => (
                          <CommandItem
                            value={category.title}
                            onSelect={() => onCategorySelect(category)}
                            key={category.id}
                          >
                            <Check
                              className={cn(
                                'mr-2 h-4 w-4',
                                selectedCategories.find(
                                  (c) => c.id === category.id
                                )
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                            {category.title}
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormItem>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CategoryName = styled.span`
  display: inline-block;
  background-color: ${colors.blue.tertiary};
  padding: 3px 6px;
  margin: 2px;
  border-radius: 4px;
  &:hover {
    background-color: #bc382a;
  }
`;
