import axios, { AxiosResponse } from 'axios';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import { SaleCategory } from '../../types';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';

type DeleteCategoryResponse = {
  categoryId: string;
  saleIds: number[];
};

const deleteCategory = async (id: string) => {
  const { data } = await axios.delete<AxiosResponse<DeleteCategoryResponse>>(
    `/v1/category/${id}`
  );
  return data.data.categoryId;
};

export const deleteCategoryMutation = () => {
  return mutationOptions({
    mutationFn: deleteCategory,
    onSuccess: updateCache,
  });
};

const updateCache = (categoryId: string) => {
  // update categories cache ([QueryKeys.CATEGORIES, true|false])
  queryClient.setQueriesData<SaleCategory[]>(
    { queryKey: [QueryKeys.CATEGORIES] },
    (oldData) => oldData?.filter((category) => category.id !== categoryId)
  );
};
