import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import DownloadBlocIcon from '../../../../../assets/icons/download-icon.svg';
import CloseButton from '../../../../../components/forms/buttons/CloseButton';
import Loader from '../../../../../components/loaders/Loader';
import ImageOrVideo from '../../../../../components/miscellaneous/ImageOrVideo';
import { s3SignedURLQuery } from '../../../../../services/api/reactQuery/get/getAWSSignedURL';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../../shadcn/components/ui/form';
import colors from '../../../../../styles/colors.styles';
import { mediaFileMutation } from '../../../rest/post/postMediaFile';
import { AWS_IMAGES_URL } from '../../../constants';

type ImageUploaderProps = {
  fieldName: string;
  label?: string;
  description?: string;
  onSetImage?: (path: string) => void;
  onDeleteImage?: () => void;
  extraImagesIcon?: JSX.Element;
};

export const FormFieldMediaUploader = ({
  fieldName,
  label,
  description,
  onSetImage,
  onDeleteImage,
  extraImagesIcon,
}: ImageUploaderProps) => {
  const { control, watch, setValue, clearErrors } = useFormContext();
  const imageURL = watch(fieldName);

  const [file, setFile] = useState<File | undefined>(undefined);

  const { data, isLoading } = useQuery(s3SignedURLQuery(file?.name));
  const { mutate } = useMutation(
    mediaFileMutation(() => {
      const path = `${AWS_IMAGES_URL}${data?.path}`;
      onSetImage?.(path);
      setValue(fieldName, path, { shouldDirty: true });
      clearErrors(fieldName);
    })
  );

  useEffect(() => {
    if (data === undefined || isLoading || !file) {
      return;
    }

    mutate({ url: data.url, file });
  }, [data, file, isLoading, mutate]);

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (!file) {
      return;
    }

    setFile(file);
    event.target.value = '';
  };

  return (
    <FormField
      control={control}
      name={fieldName}
      render={() => (
        <FormItem className="grid cursor-pointer grid-cols-1 place-items-center gap-y-0.5 space-y-0">
          {label && (
            <FormLabel
              htmlFor={fieldName}
              className="row-start-1 font-sans text-sm font-semibold text-bluePrimary"
            >
              {label}
            </FormLabel>
          )}
          <ImageContainer>
            {imageURL && (
              <CloseButtonStyled
                onClick={() => {
                  setValue(fieldName, undefined, { shouldDirty: true });
                  onDeleteImage?.();
                }}
              />
            )}
            {extraImagesIcon && (
              <div
                className={`absolute left-0 top-0 rounded-full transition-colors duration-500 ${
                  imageURL ? 'bg-white text-black' : 'bg-black text-white'
                }`}
              >
                {extraImagesIcon}
              </div>
            )}
            {/* This label is used to make the <ImageOrVideo /> "clickable" by
              linking it to the input via prop `htmlFor` and covering the container */}
            <label
              htmlFor={fieldName}
              className="flex h-full w-full cursor-pointer items-center justify-center"
            >
              <ImageOrVideo
                url={imageURL || DownloadBlocIcon}
                style={{
                  objectFit: 'cover',
                  width: imageURL ? '100%' : '36px',
                  height: imageURL ? '100%' : '36px',
                  borderRadius: 6,
                }}
              />
            </label>
            <Loader isVisible={isLoading} />
          </ImageContainer>
          <FormControl className="row-start-2">
            <input
              id={fieldName}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleUploadImage(e)}
            />
          </FormControl>
          <FormMessage className="animate-shake text-center" />
          {description && (
            <FormDescription className="row-start-3">
              {description}
            </FormDescription>
          )}
        </FormItem>
      )}
    />
  );
};

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  border: 2px solid ${colors.blue.secondary};
  border-radius: 8px;
  width: 100px;
  height: 93px;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  background-color: ${colors.beige.primary};
`;
