import styled from 'styled-components';
import colors from '../../../../styles/colors.styles';
import fonts from '../../../../styles/fonts.styles';
import { FormFieldMediaUploader } from '../form/fields/FormFieldMediaUploader';
import { FormFieldTextarea } from '../form/fields/FormFieldTextarea';

export const UpcomingSaleOptionPage = () => {
  return (
    <Container>
      <OptionTitle>Page vente à venir</OptionTitle>
      <FormFieldTextarea
        fieldName="description"
        label="Description de la vente"
        classNames={{
          label: 'font-workSans italic text-bluePrimary',
          textarea:
            'min-h-28 bg-beigeTertiary text-bluePrimary focus-visible:ring-white',
        }}
      />
      <div className="flex justify-around">
        <FormFieldMediaUploader
          fieldName="additionalImages.leftUrl"
          label="Gauche"
        />
        <FormFieldMediaUploader
          fieldName="additionalImages.rightUrl"
          label="Droite"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const OptionTitle = styled.p`
  font-family: ${fonts.WorkSans.semiBold};
  color: ${colors.blue.primary};
  align-self: center;
`;
