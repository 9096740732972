import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import usePreview from '../../contexts/PreviewContext';
import colors from '../../styles/colors.styles';
import Button from './buttons/Button';
import CarouselSection from './options/sections/CarouselSection';
import SubOptionsSection from './options/SubOptionsSection';
import { DroppablePreviewBlocs } from '../../interfaces/droppable';

interface BlocOptionsSectionProps {
  onClickConfirm: (item: DroppablePreviewBlocs) => void;
  onRemoveBloc: (item: DroppablePreviewBlocs | undefined) => void;
  visible: boolean;
  selectedItem: number;
  isButtonSubmitProcessing: boolean;
  isButtonRemoveBlocProcessing: boolean;
  error: boolean;
}

const BlocOptionsSection: React.FC<BlocOptionsSectionProps> = ({
  onClickConfirm,
  onRemoveBloc,
  visible,
  selectedItem,
  isButtonSubmitProcessing,
  isButtonRemoveBlocProcessing,
  error,
}) => {
  const preview = usePreview();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    setSelectedTab(0);
  }, [selectedItem]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onClickConfirm(preview.getSelectedItem(selectedItem));
  };

  if (!visible) {
    return <DummyContainer />;
  }

  return (
    <Container>
      {preview.getBlockType(selectedItem) === 'carousel' && (
        <CarouselSection
          selectedItem={selectedItem}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
      <Form>
        <SubOptionsSection
          blockType={preview.getBlockType(selectedItem)}
          selectedTab={selectedTab}
          selectedItem={selectedItem}
        />
        <BottomCTAContainer>
          {error && <Error>Une erreur est survenue</Error>}

          <Button
            text="VALIDER"
            buttonType="primary"
            onClick={handleSubmit}
            isProcessing={isButtonSubmitProcessing}
          />
          <Button
            text="SUPPRIMER BLOC"
            buttonType="secondary"
            onClick={(e) => {
              e.preventDefault();
              onRemoveBloc(preview.getSelectedItem(selectedItem));
            }}
            isProcessing={isButtonRemoveBlocProcessing}
          />
        </BottomCTAContainer>
      </Form>
    </Container>
  );
};

export default BlocOptionsSection;

const DummyContainer = styled.div`
  display: flex;
  width: 300px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  background-color: ${colors.beige.primary};
  flex-grow: 1;

  position: -webkit-sticky;
  position: sticky;
  top: 25px;
  max-height: calc(100vh - 100px);

  overflow-y: auto;

  border: 3px solid ${colors.beige.tertiary};
  border-radius: 8px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BottomCTAContainer = styled.div`
  padding: 0 16px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 16px 0;
  grid-row-gap: 8px;
`;

const Error = styled.div`
  color: red;
`;
