import axios, { AxiosResponse } from 'axios';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import { SaleCategory } from '../../types';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';

const postCategory = async (dto: SaleCategory) => {
  const { data } = await axios.post<AxiosResponse<SaleCategory>>(
    '/v1/category',
    dto
  );
  return data.data;
};

export const postCategoryMutation = () => {
  return mutationOptions({
    mutationFn: postCategory,
    onSuccess: updateCache,
  });
};

const updateCache = (data: SaleCategory) => {
  // update categories cache ([QueryKeys.CATEGORIES, true|false])
  queryClient.setQueriesData<SaleCategory[]>(
    { queryKey: [QueryKeys.CATEGORIES] },
    (oldData) => [...(oldData ?? []), data]
  );
};
