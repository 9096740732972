import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { FormFieldInput } from '../form/fields/FormFieldInput';

export const SaleOptionDates = () => {
  const { watch, getFieldState } = useFormContext();

  const dateStart: Date | undefined = watch('dateStart');
  const dateStop: Date | undefined = watch('dateStop');
  // Gets the error that dateStart > dateStop
  const { error } = getFieldState('dates');

  const getFormattedDateWithTimeZone = (date: Date | undefined) => {
    if (!date) {
      return '';
    }

    const dateWithTimeZone = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithTimeZone.toISOString().slice(0, 16);
  };

  return (
    <Container>
      <p className="font-workSansSemiBold text-bluePrimary">
        Dates de l’affichage
      </p>
      <FormFieldInput
        fieldName="dateStart"
        type="datetime-local"
        label="Début"
        value={getFormattedDateWithTimeZone(dateStart)}
        classNames={{
          container: 'w-full flex items-center space-y-0 gap-x-2',
          label:
            'font-workSans text-bluePrimary text-sm w-1/6 justify-self-end',
          input: 'w-5/6 bg-beigeSecondary',
        }}
        cancelNativeChange
        onChange={(field, value) => {
          field.onChange(new Date(value));
          field.onBlur();
        }}
      />
      <FormFieldInput
        fieldName="dateStop"
        type="datetime-local"
        label="Fin"
        value={getFormattedDateWithTimeZone(dateStop)}
        classNames={{
          container: 'w-full flex items-center space-y-0 gap-x-2',
          label:
            'font-workSans text-bluePrimary text-sm w-1/6 justify-self-end',
          input: 'w-5/6 bg-beigeSecondary',
        }}
        cancelNativeChange
        onChange={(field, value) => {
          field.onChange(new Date(value));
          field.onBlur();
        }}
      />
      {error && (
        <p className={'animate-shake text-sm font-medium text-destructive'}>
          {error?.message}
        </p>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
`;
