import axios, { AxiosResponse } from 'axios';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import { OngoingSaleDTO } from '../../types';

type PostOngoingSaleResponseCollection = {
  id: number;
  collectionImageId?: number;
};

type PostOngoingSaleResponse = {
  ongoingSaleId: number;
  collection?: PostOngoingSaleResponseCollection;
  productId?: number;
  webPageId?: number;
};

const postOngoingSale = async (dto: Partial<OngoingSaleDTO>) => {
  const { data } = await axios.post<AxiosResponse<PostOngoingSaleResponse>>(
    '/v1/ongoing-sales',
    dto
  );

  return data.data;
};

export const ongoingSaleMutation = () => {
  return mutationOptions({
    mutationFn: postOngoingSale,
    onSuccess: updateCache,
  });
};

const updateCache = (
  data: PostOngoingSaleResponse,
  dto: Partial<OngoingSaleDTO>
) => {
  queryClient.invalidateQueries({ queryKey: [QueryKeys.ONGOING_SALES] });
};
