import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SheetTrigger } from '../../../../shadcn/components/ui/sheet';
import {
  DND_ONGOING_SALE_PREVIEW_ID,
  DND_UPCOMING_SALE_PREVIEW_ID,
} from '../../constants';
import { DraggableOngoingSale, DraggableUpcomingSale } from '../../types';
import { DraggableSaleItem } from './DraggableSaleItem';

type DroppableSalesListProps = {
  dndContainerId:
    | typeof DND_ONGOING_SALE_PREVIEW_ID
    | typeof DND_UPCOMING_SALE_PREVIEW_ID;
  draggableSales: DraggableOngoingSale[] | DraggableUpcomingSale[];
};

export const DroppableSalesList = ({
  dndContainerId,
  draggableSales,
}: DroppableSalesListProps) => {
  const { setNodeRef } = useDroppable({ id: dndContainerId });

  return (
    <SortableContext
      items={draggableSales.map((draggable) => draggable.id)}
      strategy={verticalListSortingStrategy}
      id={dndContainerId}
    >
      <SheetTrigger className="w-full bg-white">
        <div ref={setNodeRef} className="h-full">
          {draggableSales.map((draggable) => (
            <DraggableSaleItem
              dndContainerId={dndContainerId}
              draggable={draggable}
              key={draggable.id}
            />
          ))}
        </div>
      </SheetTrigger>
    </SortableContext>
  );
};
