import axios from 'axios';
import { queryClient } from '../../../../services/api/reactQuery/QueryClient';
import { QueryKeys } from '../../../../services/api/reactQuery/QueryKeys';
import { mutationOptions } from '../../../../services/api/reactQuery/utils/mutationOptions';
import { UpcomingSale } from '../../types';

const deleteUpcomingSale = async (saleId: number, isLegacySale: boolean) => {
  if (!isLegacySale) {
    await axios.delete(`/v1/upcoming-sales/${saleId}`);
  } else {
    await axios.delete(`modular/upcomingSales/upcomingSale`, {
      data: { id: saleId },
    });
  }
};

export const deleteUpcomingSaleMutation = (isLegacySale: boolean) => {
  return mutationOptions({
    mutationFn: (saleId) => deleteUpcomingSale(saleId, isLegacySale),
    onSuccess: updateCache,
  });
};

const updateCache = (_: void, saleId: number) => {
  queryClient.setQueryData(
    [QueryKeys.UPCOMING_SALES],
    (oldData: UpcomingSale[]) => oldData.filter((sale) => sale.id !== saleId)
  );
  // queryClient.invalidateQueries({ queryKey: [QueryKeys.UPCOMING_SALES] });
};
