import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EyeClosed from '../assets/icons/eye-closed.svg';
import EyeOpenned from '../assets/icons/eye-openned.svg';
import Button from '../components/forms/buttons/Button';
import Checkbox from '../components/forms/inputs/Checkbox.form';
import Input from '../components/forms/inputs/Input';
import TextArea from '../components/forms/inputs/TextArea';
import { OrderConfirmationAdSection } from '../components/settings/OrderConfirmationAdSection';
import {
  SearchCategories,
  SearchCategory,
  SearchCategoryId,
} from '../constants/search';
import {
  getAllSettings,
  updateAllSettings,
} from '../services/settings/settings.service';
import colors from '../styles/colors.styles';
import fonts from '../styles/fonts.styles';
import { PopUpAppSection } from '../components/settings/PopUpAppSection';

const Settings: React.FC = () => {
  const [androidMinAppVersion, setAndroidMinAppVersion] = useState('');
  const [iOSMinAppVersion, setiOSMinAppVersion] = useState('');
  const [colorSwatchColors, setColorSwatchColors] = useState('');
  const [affiliationText, setAffiliationText] = useState('');
  const [affiliationParagraph, setAffiliationParagraph] = useState('');
  const [showAffiliation, setShowAffiliation] = useState(true);
  const [stripePaymentVersion, setStripePaymentVersion] = useState<'V1' | 'V2'>(
    'V1'
  );
  const [enablePayPalPayments, setEnablePayPalPayments] = useState(true);
  const [selectedCategory, setSelectedCategory] =
    useState<SearchCategory | null>(null);
  const [hiddenSubCategories, setHiddenSubCategories] = useState<
    Map<SearchCategoryId, string[]>
  >(new Map());
  const [success, setSucess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const formatAppVersion = (version: string) => {
    const formattedVersion = version.trim();
    return formattedVersion ? formattedVersion : null;
  };

  const onSubmit = async () => {
    setSucess(null);
    setError(null);
    setTriggerSubmit(true);

    try {
      const colorsMap = JSON.parse(colorSwatchColors);

      const duplicateColors: string[] = [];
      Object.keys(colorsMap).forEach((key) => {
        if (
          colorSwatchColors.indexOf(`"${key}"`) !==
          colorSwatchColors.lastIndexOf(`"${key}"`)
        ) {
          duplicateColors.push(key);
        }
      });

      if (duplicateColors.length === 1) {
        setError(`La couleur ${duplicateColors[0]} est renseignée en double`);
        return;
      } else if (duplicateColors.length > 1) {
        setError(
          `Les couleurs ${duplicateColors.join(', ')} sont renseignées en double`
        );
        return;
      }
    } catch (e) {
      setError('Format des pastilles couleurs invalide');
      return;
    }

    const formattedAndroidMinAppVersion =
      formatAppVersion(androidMinAppVersion);
    const formattediOSMinAppVersion = formatAppVersion(iOSMinAppVersion);

    setAndroidMinAppVersion(formattedAndroidMinAppVersion ?? '');
    setiOSMinAppVersion(formattediOSMinAppVersion ?? '');

    await updateAllSettings({
      androidMinimumAppVersionRequired: formattedAndroidMinAppVersion,
      iosMinimumAppVersionRequired: formattediOSMinAppVersion,
      colorSwatchColors,
      affiliationText,
      affiliationParagraph,
      showAffiliation,
      stripePaymentsVersion: stripePaymentVersion,
      enablePayPalPayments,
      hiddenSearchCategories: JSON.stringify(
        Object.fromEntries(hiddenSubCategories)
      ),
    });

    setTimeout(() => {
      setSucess('Sauvegardé');
      setTriggerSubmit(false);

      setTimeout(() => {
        setSucess(null);
      }, 1500);
    });
  };

  useEffect(() => {
    getAllSettings().then((settings) => {
      setAndroidMinAppVersion(settings.appMinimumVersions.android ?? '');
      setiOSMinAppVersion(settings.appMinimumVersions.ios ?? '');
      setColorSwatchColors(
        settings.colorSwatchColors
          ? JSON.stringify(settings.colorSwatchColors)
          : ''
      );
      setAffiliationText(settings.affiliation.affiliationText ?? '');
      setAffiliationParagraph(settings.affiliation.affiliationParagraph ?? '');
      setShowAffiliation(settings.affiliation.showAffiliation ?? true);
      setStripePaymentVersion(settings.stripePaymentsVersion ?? 'V1');
      setEnablePayPalPayments(settings.enablePayPalPayments ?? true);
      setHiddenSubCategories(
        new Map(Object.entries(settings.hiddenSearchCategories ?? {})) as Map<
          SearchCategoryId,
          string[]
        >
      );
    });
  }, []);

  return (
    <Container>
      <Title>Paramètres généraux</Title>

      <Section>
        <SectionTitle>Paiements</SectionTitle>
        <SectionInfo>Paramètres des options de paiements.</SectionInfo>

        <InputWrapper>
          <label htmlFor="stripe-payment-version">
            Version du système de paiement par carte bancaire :{' '}
          </label>
          <select
            id="stripe-payment-version"
            onChange={(evt) =>
              setStripePaymentVersion(evt.currentTarget.value as 'V1' | 'V2')
            }
          >
            <option value="V1" selected={stripePaymentVersion === 'V1'}>
              V1
            </option>
            <option value="V2" selected={stripePaymentVersion === 'V2'}>
              V2
            </option>
          </select>
        </InputWrapper>

        <InputWrapper>
          <Checkbox
            label="Activer les paiements PayPal"
            checked={enablePayPalPayments}
            onChange={() => setEnablePayPalPayments(!enablePayPalPayments)}
          />
        </InputWrapper>
      </Section>

      <Section>
        <SectionTitle>Parrainage</SectionTitle>
        <SectionInfo>Paramètres du parrainage.</SectionInfo>

        <InputWrapper>
          <Input
            type="text"
            name="affiliationText"
            label="Titre du parrainage"
            value={affiliationText}
            onChange={(e) => setAffiliationText(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper>
          <Input
            type="text"
            name="affiliationParagraph"
            label="Texte du parrainage"
            value={affiliationParagraph}
            onChange={(e) => setAffiliationParagraph(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper>
          <Checkbox
            label="Afficher l'onglet de parrainage"
            checked={showAffiliation}
            onChange={() => setShowAffiliation(!showAffiliation)}
          />
        </InputWrapper>
      </Section>

      <Section>
        <SectionTitle>Versions minimales</SectionTitle>
        <SectionInfo>
          Renseignez ici les versions minimales de l'app que les utilisateurs
          doivent utiliser. Les utilisateurs ayant une version inférieure
          devront télécharger la dernière version pour pouvoir l'utiliser.
        </SectionInfo>

        <InputWrapper>
          <Input
            type="text"
            name="androidMinimumAppVersionRequired"
            label="Version minimale Android"
            value={androidMinAppVersion}
            onChange={(e) => setAndroidMinAppVersion(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="iOSMinimumAppVersionRequired"
            label="Version minimale iOS"
            value={iOSMinAppVersion}
            onChange={(e) => setiOSMinAppVersion(e.target.value)}
          />
        </InputWrapper>
      </Section>

      <OrderConfirmationAdSection triggerSubmit={triggerSubmit} />
      <PopUpAppSection triggerSubmit={triggerSubmit} />

      <Section>
        <SectionTitle>Pastilles couleur</SectionTitle>
        <SectionInfo>
          Copier-collez ici le fichier colors.js depuis le thème Shopify.
        </SectionInfo>

        <InputWrapper>
          <TextArea
            style={{
              resize: 'none',
              marginLeft: 0,
              marginRight: 0,
              width: '100%',
            }}
            placeholder="colors.js"
            name="colorSwatchColors"
            value={colorSwatchColors}
            onChange={(e) =>
              setColorSwatchColors(
                e.target.value.replace(/(var|let|const) .+ *= */gm, '')
              )
            }
            spellCheck={false}
          />
        </InputWrapper>
      </Section>

      <Section>
        <SectionTitle>Recherche</SectionTitle>
        <SectionInfo>Paramètres de l'onglet de recherche.</SectionInfo>

        <CategoriesContainer>
          {SearchCategories.map((category) => (
            <CategoryButton
              key={category.id}
              onClick={() => setSelectedCategory(category)}
              style={{
                backgroundColor:
                  selectedCategory?.id === category.id
                    ? colors.blue.secondary
                    : colors.beige.secondary,
              }}
            >
              {category.label}
            </CategoryButton>
          ))}
        </CategoriesContainer>

        {selectedCategory !== null && (
          <SubCategoriesContainer>
            {selectedCategory.subCategories.map((subCategory) => {
              const visible = !hiddenSubCategories
                .get(selectedCategory.id)
                ?.includes(subCategory.id);

              return (
                <SubCategory
                  key={`${selectedCategory.id}_${subCategory.id}`}
                  style={{ opacity: visible ? 1 : 0.6 }}
                >
                  <SubCategoryLabel>{subCategory.label}</SubCategoryLabel>
                  <ToggleVisibilityIcon
                    src={visible ? EyeOpenned : EyeClosed}
                    width={20}
                    height={20}
                    onClick={() => {
                      const mapCopy = new Map(hiddenSubCategories);
                      const currentSubCategories = mapCopy.get(
                        selectedCategory.id
                      );
                      if (!currentSubCategories) {
                        mapCopy.set(selectedCategory.id, [subCategory.id]);
                      } else if (
                        currentSubCategories.includes(subCategory.id)
                      ) {
                        mapCopy.set(
                          selectedCategory.id,
                          currentSubCategories.filter(
                            (id) => id !== subCategory.id
                          )
                        );
                      } else {
                        mapCopy.set(selectedCategory.id, [
                          ...currentSubCategories,
                          subCategory.id,
                        ]);
                      }

                      setHiddenSubCategories(mapCopy);
                    }}
                  />
                </SubCategory>
              );
            })}
          </SubCategoriesContainer>
        )}
      </Section>

      <BottomContainer>
        {Boolean(success) && <SuccessMessage>{success}</SuccessMessage>}
        {Boolean(error) && <ErrorMessage>{error}</ErrorMessage>}
        <Button buttonType="primary" text="Sauvegarder" onClick={onSubmit} />
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 25px 50px;
  width: 100%;
`;

const Title = styled.h1`
  font-family: ${fonts.Archivo.medium};
  color: ${colors.blue.primary};
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin: 0;
`;

const Section = styled.section`
  margin-top: 20px;
  background-color: ${colors.beige.primary};
  border: 2px solid ${colors.beige.tertiary};
  border-radius: 8px;
  padding: 15px;
`;

const SectionTitle = styled.h1`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.secondary};
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 15px;
`;

const SectionInfo = styled.p`
  font-family: ${fonts.WorkSans.regular};
  color: ${colors.blue.primary};
  font-size: 13px;
  margin: 5px 0;
`;

const InputWrapper = styled.div`
  margin: 10px 0;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

const SuccessMessage = styled.div`
  color: ${colors.mono.success};
  margin-right: 20px;
`;

const ErrorMessage = styled.div`
  color: ${colors.mono.danger};
  margin-right: 20px;
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CategoryButton = styled.button`
  color: ${colors.blue.primary};
  background-color: ${colors.beige.secondary};
  border: 1px solid ${colors.blue.primary};
  padding: 2em;
  font-weight: 500;
  border-radius: 4px;
  flex: 1;
  margin: 10px 5px 0;
  &:hover {
    background-color: ${colors.beige.primary};
  }
`;

const SubCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${colors.beige.tertiary};
  border: 1px solid ${colors.beige.secondary};
  border-radius: 4px;
  margin-top: 1em;
  padding: 10px;
`;

const SubCategory = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.blue.primary};
  color: ${colors.blue.primary};
  border-radius: 4px;
  margin-top: 10px;
  padding: 7px 10px;
  width: 16em;
`;

const SubCategoryLabel = styled.div`
  flex: 1;
  margin-right: 10px;
  user-select: none;
`;

const ToggleVisibilityIcon = styled.img`
  cursor: pointer;
`;

export default Settings;
