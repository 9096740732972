import { gql } from '@apollo/client';

export interface CollectionItem {
  id: string;
  title: string;
}
export interface CollectionData {
  collection: CollectionItem;
}

export interface CollectionListings {
  node: CollectionItem;
}

export interface CollectionListingsData {
  collections: {
    edges: CollectionListings[];
  };
}

export interface Products {
  node: {
    id: string;
    title: string;
  };
}

export interface ProductsData {
  products: {
    edges: Products[];
  };
}

export const getCollectionById = gql`
  query ($id: ID!) {
    collection(id: $id) {
      id
      title
    }
  }
`;

export const getCollections = gql`
  query ($limit: Int, $query: String) {
    collections(first: $limit, query: $query) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const getProducts = gql`
  query ($limit: Int, $query: String) {
    products(first: $limit, query: $query) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
