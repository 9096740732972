class TokenService {
  getJWT() {
    try {
      const jwt: string | null = localStorage.getItem('jwt');
      return jwt;
    } catch {
      return;
    }
  }

  setJWT(jwt: string) {
    localStorage.setItem('jwt', jwt);
  }

  removeJWT() {
    localStorage.removeItem('jwt');
  }
}

export default new TokenService();
